import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/maximum-overdrive-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1986 killer trucks movie, Maximum Overdrive"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 14</h1>
                    <h2>Maximum Overdrive</h2>
                    <h3>August 7, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/maximum-overdrive">Back to the episode details</Link></p>
                        <div>
                        <p>Bryan! (00:02.488)
You&#39;re listening to Bring Me the Axe. I&#39;m Brian White, one half of this morbid equation, and I&#39;m joined by my co-host and actual brother, Dave White. Dave, how you doing?</p>
<p>Dave! (00:10.807)
Uh, doing pretty good.</p>
<p>Bryan! (00:13.453)
Sound a little understated there, a little low energy.</p>
<p>Dave! (00:16.758)
Yeah, I am actually. I am today.</p>
<p>Bryan! (00:19.403)
I&#39;m a little bit too, I&#39;m a fucking long ass weekend.</p>
<p>Dave! (00:22.17)
It&#39;s been 1,000 degrees for, I don&#39;t know, a couple of weeks now.</p>
<p>Bryan! (00:26.412)
Yeah, the place I was at all weekend was like really, really air conditioned. So like I would go out and it would be like a wall of fucking oppressive heat. Then I&#39;d go inside and just freeze my ass off for hours. So little back and forth. Also, it would be really nice if literally every person in Massachusetts wasn&#39;t on I 93 at the same goddamn time that I was fucking Christ man. Now that drive is the most insane shit in the world like fuck, fuck Massachusetts man.</p>
<p>Dave! (00:48.478)
You are never going to get that.</p>
<p>Bryan! (00:55.428)
You had a nice little town there. Summerville is very nice, very nice, but God damn it getting there. Not easy, I hate it. So we practically grew up in neighborhood video stores. Steady diet of utter garbage that those shops provided us with continues unabated to this day. There&#39;s no one else I enjoy chopping it up with more about trashy movies and day. So just before we get into it, here&#39;s a little housekeeping if you want to keep up with us between episodes. You can also find us pretty much on all the socials by searching Bring Me the Axe Horror Podcast.</p>
<p>We have a sweet website now at bringmetheaxe.com. You can listen to all our past shows there and read the transcripts, that sort of thing you&#39;re into. You can also contact us directly at bringmetheaxepod at gmail.com with any questions, comments, or suggestions. Do let us know if there&#39;s a movie that you love and would like to hear us give it the business. Lastly, if you like what you hear, you can subscribe to us wherever you get your podcasts. You&#39;d be doing us a favor by leaving us a five-star review on Apple Podcasts. It really does help. It&#39;s the sort of thing that kind of boosts our visibility, takes you...</p>
<p>two fucking seconds, please just fucking do it. Do we gotta come to your house and fucking hold the phone in front of you? Like what is taking so goddamn?</p>
<p>Dave! (01:54.37)
Yeah, just do it. God damn it.</p>
<p>Dave! (02:01.66)
Ugh. Do people really read the transcripts? Is that a thing people do?</p>
<p>Bryan! (02:04.224)
Uh, no, it&#39;s actually a thing that I do to kind of hack search engine optimization, um, makes it makes us a lot more visible on search engines.</p>
<p>Dave! (02:11.886)
Because I just thought like someone really appreciated some dumb shit I said, but they want to get the quote just right.</p>
<p>Bryan! (02:18.751)
I hope not, because if you&#39;ve ever actually gone there and read the transcripts, whenever we whenever we have moments where we talk over one another, the whatever that algorithm is, it freaks out and doesn&#39;t.</p>
<p>Dave! (02:29.418)
It&#39;s like basically YouTube closed captions.</p>
<p>Bryan! (02:32.516)
Kind of. Yeah. Like it&#39;s actually it&#39;s not quite as sophisticated as the as the closed captioning. So like there will be like Brian word Dave word Brian word Dave word and it&#39;ll basically spell out like an entire five minute conversation like word by word. It&#39;s very funny. But it does actually kind of help in terms of just sort of like gaming the system and sort of like.</p>
<p>That&#39;s why I do it. I don&#39;t think anybody actually reads them, but we&#39;ve got a very sophisticated website that I&#39;m actually quite proud of. Uh, ba-ba-ba-ba. Oh yeah. So hey, we&#39;re just getting that all out of the way right at the top of the show. So this is a, this is a fun one we got for you today. I&#39;m gonna give you a little, give you a little taste here, get you, get you ready to fully appreciate what we&#39;re watching. Here comes the drill.</p>
<p>Bryan! (05:25.498)
Maximum Overdrive!</p>
<p>Dave! (05:27.79)
So you say that that&#39;s the first movie you ever directed, huh? Wouldn&#39;t have known.</p>
<p>Bryan! (05:32.004)
Yeah, I, if you want, if you, if you want something done right, you gotta get George Romero to do it. Oh, boy.</p>
<p>Dave! (05:39.466)
Yeah, that, I wanna know how many times it took him just to get through the trailer. He is, he is like positively unhinged in that thing. He looks wild eyed.</p>
<p>Bryan! (05:44.346)
trailer!</p>
<p>Bryan! (05:50.06)
He&#39;s got, yeah, he&#39;s got that. He&#39;s got that wonky eye going on. And they do that thing where like, you know, I&#39;m going to scare the hell out of you. And he&#39;s like pointing at the camera. Ah, it&#39;s kind of cringe, but I don&#39;t think there&#39;s ever a trailer where the fucking filmmaker himself comes out and like announces, hey, I&#39;m the guy who made this movie, boy, you&#39;re going to love it. Oh, yeah. OK, OK. But obviously, the big sale here is Stephen King himself, right?</p>
<p>Dave! (05:59.522)
Yeah.</p>
<p>Dave! (06:10.058)
Alfred Hitchcock does it.</p>
<p>Um.</p>
<p>Dave! (06:17.622)
I mean, one of the posters has him on the poster taking up like half of the thing, the top half. It&#39;s like just him. And that is not a man you want creeping out at you.</p>
<p>Bryan! (06:23.436)
Yeah. What? No, he&#39;s got kind of I remember really when I go to the bookstore, you find his books whenever they whenever they put his photo on the jacket. It was always the most deranged looking picture of him. They could find</p>
<p>Dave! (06:41.88)
He&#39;s had that weird bowl cut for like 40 years.</p>
<p>Bryan! (06:45.196)
Yeah, yeah. But like there would be like somewhere he had his bearded and he just looked like insane with the beard. Yeah, I don&#39;t know the whole thing. Like obviously there was this there was a strategy at work there. Stephen fucking King making a goddamn movie.</p>
<p>Dave! (06:59.742)
Oh yeah, I mean, this is the movie that cocaine built. This is the cokiest movie I&#39;ve ever seen.</p>
<p>Bryan! (07:04.12)
Oh yeah, oh yeah, I describe this movie regularly as cocaine the motion picture. It there&#39;s</p>
<p>Dave! (07:08.546)
And I mean, he has even admitted that he was, I believe he said he was coked out of his mind.</p>
<p>Bryan! (07:13.656)
Yeah, yeah, this movie. Yeah, yeah, I, you know.</p>
<p>Dave! (07:16.256)
And boy does it show!</p>
<p>Dave! (07:20.994)
This thing is like 1980s, the movie. Like you can&#39;t, it is barely a story. It is nothing but explosion set pieces strung together with like this kind of weird human mundanity that&#39;s like, there&#39;s no, you have no context. There&#39;s no interiority to any of these people. The plot just sort of meanders and then it&#39;s over.</p>
<p>Bryan! (07:24.716)
It&#39;s about as 1980s as it gets. Yeah.</p>
<p>Bryan! (07:45.76)
Oh, yeah, it&#39;s a stunt and pyro show, basically. And thank God, because I love that. What what was the music playing in that trailer? Because that music is not in the movie. That sounds like maybe it was from Christine. Yeah.</p>
<p>Dave! (07:50.806)
Yeah, it&#39;s like what?</p>
<p>Dave! (07:58.31)
It&#39;s probably was. It sounds like Alan Howarth or and or John Carpenter. But yeah, because that.</p>
<p>Bryan! (08:03.096)
Yeah, like it definitely has that Prophet 5 kind of synth sound and that beepy sort of escape from New York thing they were doing.</p>
<p>Dave! (08:08.938)
Yeah. Because the music for this, all of it is ACDC. Even the incidental music is ACDC. Oh, there&#39;s other songs.</p>
<p>Bryan! (08:16.035)
Yeah, there&#39;s that kind of stabby thing they do whenever like there&#39;s vehicle violence. But yeah, for the most part, like ACDC provided all of the music for this movie. So.</p>
<p>Dave! (08:28.802)
But they&#39;re like weird. They&#39;re not like the album version. It&#39;s like some of them seem like weird cuts from something else. Like there&#39;s the rock and roll like noise pollution that&#39;s like got this long rambling intro that just goes on for like two minutes.</p>
<p>Bryan! (08:37.628)
I think, well, I&#39;ll talk about this.</p>
<p>Bryan! (08:45.752)
Yeah, I think I think that with the soundtrack they so they recorded three original tracks. So who made who is the obvious one because that&#39;s the name of the album that was. It&#39;s a hell of a song like this is a really great, great record. And then the other two there&#39;s chase the ace and there&#39;s another one that I can&#39;t remember the name of that were also recorded for it, but they were both instrumentals. And then I think the rest of them are either just album, they might be album tracks.</p>
<p>Dave! (08:55.039)
And it&#39;s a fucking banger.</p>
<p>Bryan! (09:12.332)
I suspect that they were re-recorded for this because their album tracks from like Back in Black and Fly on the Wall.</p>
<p>Dave! (09:15.065)
That&#39;s the way it sounds.</p>
<p>Dave! (09:19.998)
It&#39;s a weird greatest hits that&#39;s like, but they&#39;re not the original versions.</p>
<p>Bryan! (09:24.004)
Yeah, because there&#39;s also there&#39;s a Bond Scott song on here, but I don&#39;t think it&#39;s Bond Scott. I think it&#39;s actually Brian Johnson singing the Bond Scott part. So, yeah, let&#39;s do some facts. The year was 1986. So some other movies released that year, Invaders from Mars. Aliens, which who doesn&#39;t love aliens? Yeah, but that&#39;s what that one cocaine really did it some favors. Chopping Mall.</p>
<p>Dave! (09:44.738)
Speaking of cocaine...</p>
<p>Dave! (09:49.902)
Yeah dude. Hell yeah!</p>
<p>Bryan! (09:53.176)
Yeah, right. Which board also came out in 1986? No, no, no. Tawny Ketane, yeah, that&#39;s I believe that was her. No, no, because she&#39;s also in, she&#39;s in Bachelor Party, but that was like her first like starring role. Not a good movie, man. Not a good movie. Also Nomads. Have you ever seen Nomads? It was, it might still be on Shudder. It&#39;s a Pierce Brosnan movie. And.</p>
<p>Dave! (09:55.106)
I don&#39;t know about that. Is that Tawny Cotain in that movie?</p>
<p>Dave! (10:07.467)
No.</p>
<p>Dave! (10:12.782)
Think so.</p>
<p>Bryan! (10:21.872)
I tried to watch it and I turned it off five minutes into the movie because here&#39;s the thing with me and Pierce Brosnan. I think he&#39;s fine. Remington Steele, he got to basically play a James Bond like parody in the 1980s and in the 1990s he was British and handsome enough to play James Bond himself. And then today he is painfully handsome for a man in his 60s and I look like this. It pisses me off like you wouldn&#39;t believe, but I&#39;m not Pierce Brosnan. That man has like.</p>
<p>Dave! (10:45.486)
Well, you&#39;re not Pierce Brosnan.</p>
<p>Bryan! (10:51.108)
Bulletproof genetics like that shit should be fucking bottled. I hope they have samples somewhere. But anyways, Nomads, right? He he plays. I think he plays basically like an anthropologist in the movie. But the thing is and I don&#39;t know why maybe it was written into the script, but he&#39;s a French guy. And so he does the movie with this like French accent. That is one of the most insane things I&#39;ve ever heard. It&#39;s basically let&#39;s see Pepe Le Pew.</p>
<p>Dave! (11:20.499)
Hmm</p>
<p>Bryan! (11:22.085)
a better French accent. Anybody else that you could think of, this is the most insane thing. They could have just been like, yes, I&#39;m glad to see that you&#39;re here from Paris and he could have just done his regular accent and it would have been fine. Basically, yeah, like, you know, mustache twirling and baguettes and fucking berets and shit. It&#39;s one of the most deranged movies I&#39;ve ever seen.</p>
<p>Dave! (11:34.718)
It sounds like it&#39;s a lot of like, uh-huh-huh.</p>
<p>Dave! (11:41.644)
Yeah!</p>
<p>Bryan! (11:48.384)
I couldn&#39;t get through it because of the accent. I had to shut it off. Anyways, rounding out 1986, a movie that I just watched recently and I fucking love it as much as I did when I was 12, Critters.</p>
<p>Dave! (12:00.074)
I have not seen that probably since the 80s.</p>
<p>Bryan! (12:02.932)
It&#39;s great. It&#39;s Chiodo Brothers doing all the special effects. It&#39;s very silly. It&#39;s just as ridiculous as it gets. And it&#39;s the kind of movie that like it had a very out-sized impact on my imagination when I was a kid. Like it&#39;s essentially like Bart Simpson versus a bunch of like ravenous little furry creatures. I love it. Love it to death. 1986, hell of a year for movies. Also just recently watched Invaders from.</p>
<p>for the first time in like a wicked long time. And I gotta tell you, it stands up. It&#39;s a hell of a movie. Karen Black is in fact in that. And the boy who plays David Gardner is her actual son.</p>
<p>Dave! (12:37.698)
Karen, is Karen Black in that?</p>
<p>Dave! (12:45.122)
Oh, David Gardner. Isn&#39;t that the name of the kid from, what&#39;s the one about communism in the 50s? Vision of the Body Snatchers, isn&#39;t that his name?</p>
<p>Bryan! (12:46.136)
Yep. It&#39;s a</p>
<p>Bryan! (12:57.136)
Oh, I don&#39;t know. The main character, the guy that Kevin. Oh, I don&#39;t remember. I can&#39;t remember the last time I actually watched the original body snatchers, I&#39;ve watched. The 70s body snatchers a bunch, because it&#39;s the fucking. It&#39;s fucking great. So, Drew, cast and crew director, obviously, we said it&#39;s Stephen King, just like the trailer said, so at the time.</p>
<p>Dave! (13:02.478)
I think it&#39;s the kid.</p>
<p>That doesn&#39;t matter.</p>
<p>Dave! (13:13.302)
They&#39;re both on movie.</p>
<p>Bryan! (13:25.784)
He was the biggest author of the 1980s. Basically a guaranteed box office draw.</p>
<p>This movie, I honestly, I don&#39;t really know a whole lot about the history of this movie. I don&#39;t know how this deal came together, but it really does sort of make sense. Okay, go for it.</p>
<p>Dave! (13:43.462)
I will tell you how it came together. It is this, as I understand it. So Dino DiLorentis obviously made a bunch of shit in the seventies. And I think the, I think kind of what happened is like, he really noticed that like B movies were really catching on, like the kind of cheaper, not knockoffs exactly, but cheaper stuff.</p>
<p>Bryan! (14:07.684)
Yeah, so this is a we deliberately chose to Dino De Laurentiis productions for this series because that&#39;s a really interesting thing because De Laurentiis is not exactly a schlocky guy. He&#39;s just a very savvy businessman. But like his output kind of bridges a weird gap between the respectable and the exploitative, like Serpico is a great fucking movie. Three days of the condor. Fucking rules. Like there are some really.</p>
<p>Dave! (14:12.46)
Yeah.</p>
<p>Dave! (14:33.259)
Deathwish is pretty good.</p>
<p>Bryan! (14:35.404)
Yeah, the original Death Wish, I think, is a is a solid movie. It gets kind of shit on because it&#39;s, you know, it&#39;s grimy and it&#39;s kind of nasty and the message is really fucking ridiculous. But it&#39;s a solid, like a very good movie. And then like going into the 80s, I mean, in the middle of the 70s, he did that bizarre King Kong remake with.</p>
<p>Dave! (14:47.062)
That&#39;s entertaining.</p>
<p>Dave! (14:53.774)
So that was, this is kind of how this happens because he does the, that&#39;s the one with like Jeff Daniels in it, right? And Faye Dunaway. I think Jeff Bridges.</p>
<p>Bryan! (15:00.412)
Jeff is it Jeff Daniels or is it Jeff Bridges? Yeah, not Faye Dunaway. Jessica Lang. Jessica Lang is in it.</p>
<p>Dave! (15:10.73)
Yes. So there he makes that. And so they, he comes to Hollywood because he had been shooting mostly in like Italy, obviously, and then would come here, but he was like, I&#39;m gonna move here. And he wanted to open a, like his own production company, which he did. And they base it in North Carolina, because it is, and he, I think he just really liked the state.</p>
<p>Bryan! (15:32.728)
Right, because it was like a right to work state, which...</p>
<p>Dave! (15:39.854)
And obviously it&#39;s one of those places that can stand in for a lot of different locations. So they start this company and then he&#39;s like, I, you know, he sets about buying all these properties. And obviously, if you want to make money by Stephen King properties, because they had done, well, obviously, carries a fucking huge hit.</p>
<p>Bryan! (15:59.832)
Right, because like, yeah, one of the things I noticed when I looked over his filmography, like there were a shitload, like the 80s was the golden age of the Stephen King theatrical adaptation, and like 90% of them were produced by Dillerantis.</p>
<p>Dave! (16:07.103)
Mm.</p>
<p>Dave! (16:10.902)
Yeah, so he, you know, so they&#39;ve got Carrie, they&#39;ve got the Shining, obviously huge. I think even Salem&#39;s Lot, the TV movie did really well. And so it was like, this is an obvious choice. And so he buys, he wants four different things. One of them is original, well, kind of original. So he wants, he buys Firestarter, Dead Zone, Cat&#39;s Eye, one other one.</p>
<p>Bryan! (16:19.437)
Yeah, oh yeah!</p>
<p>Bryan! (16:32.713)
zone.</p>
<p>There&#39;s that maximum overdrive. There&#39;s also a silver bullet.</p>
<p>Dave! (16:40.982)
Yes, so he buys those four and their deal was, Stephen King was like, yes, I will, I will sell you these. You can take these, but I want to direct something. And Dino De Laurentiis was like, you know what? If you want to direct, then direct you shall. You must do this. And so, but that was the deal. It was like, you can have these, but I want to make one too. And he was like, okay, fine. Like it&#39;s probably not going to be very good, but actually Dino De Laurentiis was really enthusiastic about pretty much everything. And so,</p>
<p>Bryan! (16:56.964)
Yeah, yeah.</p>
<p>Dave! (17:10.702)
He probably was really into this movie, but that&#39;s kind of how it gets made and those It&#39;s the same crew on everything now and a lot of the people who worked on it are Italian and because he just brought them, you know, he&#39;s people he&#39;s And so they would be and like these are people that worked with like Fellini and so they here they are They&#39;re just kind of like you want me to do what? Okay, I guess because fucking Italian filmmakers are like sure. It&#39;s a job man</p>
<p>Bryan! (17:20.484)
Yeah. Yeah, it&#39;s brought him over this like his people, he trusts him. So he&#39;s gonna</p>
<p>Bryan! (17:36.268)
Right, right. It&#39;s yeah, like worth noting Italian, like Italian production, Italian filmmakers, Amityville to handed it over to Dardano, Suketti script by or not Dardano Suketti&#39;s. He wrote it. Damiano Damiani directed it. That&#39;s what I meant. But yeah, that was just his that was his pattern.</p>
<p>Dave! (17:50.198)
I mean, and Dardano Siketty broke fucking everything.</p>
<p>Dave! (17:58.813)
And you&#39;ll notice like the directors of these movies are kind of nobodies, like even, you know, Cronenberg wasn&#39;t anybody really at the time.</p>
<p>Bryan! (18:06.168)
Yeah, oh, they were cheap. And that&#39;s the thing, like, D&#39;Ameono, D&#39;Ameani was also not a very, like, you think of Italian directors, most of them pretty fucking prolific. D&#39;Ameani was not.</p>
<p>Dave! (18:16.342)
Yep. I think he was more of a writer than he was a director. He was something else. So that&#39;s what he does. He has these crews and he gets these kind of newer, cheap directors like, well, there&#39;s Cujo too, I think. I don&#39;t remember if that&#39;s him though, but that&#39;s another one that&#39;s the guy who directed that. I can&#39;t remember his name, but he&#39;s like a really good director. But none of them, I think he&#39;s the one who did, who did Firestarter? I think it&#39;s him, whatever his name is. But.</p>
<p>Bryan! (18:20.6)
most likely, but he wasn&#39;t much of a director.</p>
<p>Bryan! (18:30.857)
No, that&#39;s right. I can&#39;t remember either.</p>
<p>Dave! (18:45.322)
Yeah, so that&#39;s kind of how this happens. So he&#39;s like, well, the rest of these movies are being made by kind of nobodies. So may as well have Stephen King make one. And that is how we get this movie. Well, that and a ton of cocaine because everybody in this movie is fucked up the whole time.</p>
<p>Bryan! (18:56.35)
Yeah.</p>
<p>Oh yeah, 85, 86 was peak cocaine in America. I remember, I mean fucking Miami Vice was floated entirely on the premise that cocaine was fucking everywhere.</p>
<p>Dave! (19:10.754)
Like every close-up shot of Emilio Estevez in this, he is high as hell. His eyes are glassy. He is covered in sweat.</p>
<p>Bryan! (19:17.468)
blushed and red faced red-eyed like yeah, so yeah, so this is this is 86 by 1986 easily half of Stephen King&#39;s published output had been adapted to screen in some manner and uh and also it kind of makes sense that he would want to direct his own movie because he he&#39;s a he&#39;s a well-documented fan of horror movies. Have you ever read dance macabre?</p>
<p>Dave! (19:19.296)
Yeah.</p>
<p>Dave! (19:43.858)
No. That&#39;s the bad one, isn&#39;t it? That&#39;s the one where you&#39;re like, oh, Jesus Christ, man. You just stop talking, stop saying things.</p>
<p>Bryan! (19:44.897)
That&#39;s his like, that&#39;s like the novel.</p>
<p>Bryan! (19:51.244)
Well, well, that&#39;s the one that&#39;s like a novel length treatise on like horror and fan.</p>
<p>Dave! (19:55.71)
Yeah, it&#39;s like wild shit, wild shit that he later goes on to like contradict himself constantly on. Because people will be like, well, you said this. And he was like, I said that guy must have drunk. Like he has this weird bluntness, but he just sort of says whatever is in the front of his mind all the time. And I feel like that whole book is like that. But he&#39;s also he I think he considers himself very liberal. I think people assume he is very liberal. He is actually more of a kind of centrist, moderate.</p>
<p>Bryan! (20:02.501)
Yeah.</p>
<p>Bryan! (20:07.032)
Yeah.</p>
<p>Dave! (20:24.542)
almost libertarian in his approach to things because he has a very small town, like worldview.</p>
<p>Bryan! (20:31.undefined)
Well, that&#39;s the thing. Yeah. Because I mean, maybe at this time, nowadays, I follow him on social media and he is very, very Democrat. But at the time, every one of his stories had that small town sensibility. All of his heroes.</p>
<p>Dave! (20:38.866)
Yeah, he&#39;s, I think he&#39;s really...</p>
<p>Dave! (20:45.678)
I mean, there&#39;s a fucking horrible story about how he like goes to some Black Panther meeting and is like trying to stick it to the Panthers because he doesn&#39;t like their agenda. It&#39;s like, Jesus Christ, man. And like he has changed a little bit. I think he&#39;s a little more self aware because he used to be not great when people would challenge him on things or ask him about things like there was a there&#39;s a really incredible podcast called Just King Things. Everybody should listen to it. It&#39;s fucking great.</p>
<p>Bryan! (20:56.364)
Yeah. But like, but yeah, he, he.</p>
<p>Bryan! (21:10.776)
Yeah, oh yeah, they&#39;re great. I love them. They&#39;re fantastic.</p>
<p>Dave! (21:14.058)
but they go through and read a lot of the Castle Rock newsletter that was put out pretty regularly throughout the 80s. And like people write in and be like, you know, why, why do you use the N word all the time? Like, why do you feel so free to make your black characters these sort of really put upon victims all the time? And he&#39;s just like, well, I don&#39;t know what you&#39;re talking about. I don&#39;t see color. It&#39;s very much that where it&#39;s like, I know you mean well, but you need to not talk and listen right now. Someone is giving you a fair critique.</p>
<p>Bryan! (21:39.564)
Yeah, also at a certain point, how much of that was also fueled by his drug use because cocaine will make you think probably probably. I mean, the thing is, is I mean, that&#39;s got to also come with being one of the largest cultural exports of the 1980s. He certainly</p>
<p>Dave! (21:50.162)
I think he&#39;s just kind of an arrogant guy, or he was at least.</p>
<p>Bryan! (22:01.356)
after you cleaned up things change. I definitely notice, and I don&#39;t know where you&#39;re at on his novels, I don&#39;t, going into the 90s, I don&#39;t really care for any of that at all. Like...</p>
<p>Dave! (22:10.57)
I don&#39;t think I&#39;ve read it many from the 90s. I&#39;ve read a lot of them for some reason. I guess I think when you&#39;re a kid, this is like where you gravitate to as a young reader.</p>
<p>Bryan! (22:17.668)
Sure, like all of my favorite Stephen King stuff is, I love the short stories that are in Night Shift and Skeleton Crew. They&#39;re, some are better than others and some are really not very good at all, but the bulk of the short stories are fucking outstanding. My favorite novel of his, hands down, is Salem&#39;s Lot. I love The Dead Zone. I also love Firestarter.</p>
<p>because it&#39;s kind of, I mean, it&#39;s not a great novel, but it&#39;s kind of a look at like what his run on the X-Men would have been like.</p>
<p>Dave! (22:50.858)
And that, but that&#39;s also like where he was coming from at that time, because he&#39;s not really, he is considered the master of horror, but I don&#39;t think he really started out being a horror writer. I don&#39;t think he wanted to be. You know, his books are, were kind of, they had frightening elements to them, but they were really like science fiction. Even The Shining is kind of like, it&#39;s more about that kind of TK, you know, psychic bullshit.</p>
<p>Bryan! (23:11.568)
Yeah, yeah. Yeah, I mean, also, really, like what people consider can consider is like Magnum Opus is like the Dark Tower, which is really a fantasy series. I&#39;ve read the first two. I thought the first one was OK. The second one just didn&#39;t hook me, so I never went any further. The mythology of it sounds fascinating. I don&#39;t know. I can&#39;t really get into it, but also I don&#39;t particularly care for the stand or it.</p>
<p>Dave! (23:23.594)
I&#39;ve never read any of them, they sound exhausting.</p>
<p>Dave! (23:40.294)
Oh god, no, The Stand is a terrible book.</p>
<p>Bryan! (23:42.812)
I mean, that&#39;s the one we&#39;re making. It&#39;s pretty fucking hot takes right now. Like those are, these are like the stand is like, it&#39;s so long. Yeah.</p>
<p>Dave! (23:48.322)
It is a rambling novel that is in parts just very badly written. At a certain point you can tell he&#39;s just struggling to get himself out of some rut that he&#39;s in, but it takes like 200 pages to do it. Because the thing about Stephen King, especially at this period is that.</p>
<p>Bryan! (24:03.536)
Jesus Christ, his novels are so fucking long.</p>
<p>Dave! (24:06.41)
He is because he&#39;s considered to be uneditable, which means he basically, whatever he says goes, his editor and the publisher would just be like, sure, whatever you want, Steve. And, you know, most people, I am someone who enjoys editing. Most people don&#39;t like editing. He clearly didn&#39;t think that he needed to be because he actually went back and put all that dumb shit that got cut out of the stand, and that&#39;s the only one you can get now.</p>
<p>Bryan! (24:27.536)
There&#39;s right in the in the 90s, they were like the unabridged version and I was like, damn, that sounds</p>
<p>Dave! (24:34.066)
And it is dog shit. None of that needed to be in there. Like he is this, he tells these rambling stories and I think he is capable of great writing and you can see it sometimes, but he&#39;s just as capable of bad writing. And I mean, his treatment of women is horrible. He relies on stereotypes a lot. Like he really leans into like gay stereotypes, black stereotypes, like any minority. It&#39;s pretty much just like anyone who is not a white man from a rural place.</p>
<p>Bryan! (24:46.524)
Oh yeah.</p>
<p>Dave! (25:03.786)
he cannot conceive of what their life would be like. So he has to kind of fall back on really shitty kind of flimsy portrayals of.</p>
<p>And a lot of that comes through in this movie, you can tell like every single one of these characters is a fucking stereotype. And like the thinnest, cheapest version of it, it&#39;s like the basest representation you can find. Like these people all have names, they may as well have not had them. They barely say them, you don&#39;t need them. I mean, there&#39;s like a fuckload of people in this movie for some reason, and I don&#39;t know who half of them are, they&#39;re just there to get killed.</p>
<p>Bryan! (25:31.928)
Yeah, right.</p>
<p>Bryan! (25:40.024)
Yeah, for sure. So I mean, back to the back to Maximum Overdrive. So King gets the screen credit, but I fucking I hate talking about shadow directors because a lot of the time it&#39;s bullshit. And the one thing that everybody. Yeah, like everybody, everybody points to fucking Poltergeist. It&#39;s not true. Toby. It&#39;s a great movie that fucking Toby Hooper made. Spielberg was on set. He&#39;s a hands on producer. But that is.</p>
<p>Dave! (25:54.546)
I told you where I stand on it. I think it&#39;s just a myth.</p>
<p>Which is a really shitty thing to say.</p>
<p>Bryan! (26:09.128)
100% a Tobe Hooper movie. Fuck everybody. Yeah.</p>
<p>Dave! (26:10.122)
Yeah. And everybody who worked on that, everybody who worked on it is like, yeah, no, Toby directed that movie. That&#39;s all like, yes, even Spielberg was there and had a lot of input. But that is Toby Hooper&#39;s movie. You can see it in other shit. It&#39;s not like everyone&#39;s just like, well, no, because he made Texas Chainsaw. It&#39;s like, yeah, he made 100 other movies, too. Why don&#39;t you name three of those?</p>
<p>Bryan! (26:16.441)
Yep.</p>
<p>Bryan! (26:21.956)
Yeah. So yeah.</p>
<p>Bryan! (26:29.592)
Yeah, yeah.</p>
<p>Right. The rumor with this one is that George Romero directed a lot of it. And I think that is somewhat true in the sense that, one, he was definitely a regular presence on set. This is a time that he and that Stephen King and George Romero, they&#39;re very tight. So it kind of follows that if he&#39;s on set, he&#39;s probably helping King out, who realized</p>
<p>Probably almost immediately that making a movie is really fucking hard.</p>
<p>Dave! (27:04.034)
But the thing I&#39;m not, why I&#39;m skeptical of that rumor is that George Romero has been very upfront about the fact that he doesn&#39;t really, well didn&#39;t really know how to make movies any other way than the way he made them. Like, so he would say, like, I&#39;m not, when it comes to, that&#39;s why he had a particular, I think it was a four picture deal with, I can&#39;t remember what studio, but they were like, we will give you, you know, in order to make whatever movie, I can&#39;t remember what the movie was, but.</p>
<p>They gave him this big deal. And he had never worked with a studio before. And he was like, I was way out of my depth. I have no idea how to do, like I know how to do everything myself and how to come at it that way. But I don&#39;t know how to make a movie with all this studio backing. That&#39;s why I find it a little hard to believe that you would be like, I&#39;m gonna call that guy to come help me on what is a probably pretty expensive movie.</p>
<p>Bryan! (27:41.66)
Oh yeah, yeah.</p>
<p>Bryan! (27:54.988)
Yeah, yeah. I mean, there is a lot of shit blowing up in this movie, like big pyro, lots and lots of stuff exploding all over the place. Like this is not. It&#39;s it&#39;s not a small movie at all. Like, I mean, I could understand if he were there consulting just quietly in a friendly way. Yeah, yeah. But but yeah, Stephen King definitely had cocaine confidence in this movie.</p>
<p>Dave! (27:59.308)
Yeah.</p>
<p>Dave! (28:13.142)
Yeah, I mean, I&#39;m sure the cinematographer probably did a lot more.</p>
<p>Dave! (28:24.214)
Oh my god. That you could des- that&#39;s the parenthetical title. Maximum Overdrive, Cocaine Confidence. Explosions, the movie.</p>
<p>Bryan! (28:28.884)
Cocaine coffee. Yep. Let&#39;s get to the cast. So obviously star of the movie is Emilio Estevez, son of Martin. Yes.</p>
<p>Dave! (28:39.426)
And this is like peak, peak Emilio Estevez. He&#39;s looking his best. He&#39;s at his peak of his power.</p>
<p>Bryan! (28:44.92)
Right, just off of Breakfast Club, just off of St. Elmos Fire, he&#39;s the quintessential Brat Packer, son of Martin Sheen, brother of Charlie Sheen, nephew of Joe Estevez. You know, he&#39;s been in at this point, like I said, Breakfast Club, he&#39;s been in repo, man, he&#39;s gonna be in young guns in like a couple of years. He he&#39;s the outsiders. He is the 80s personified and is probably the least problematic son of Martin Sheen.</p>
<p>Dave! (29:06.743)
Isn&#39;t the outsiders?</p>
<p>Bryan! (29:15.94)
We also get.</p>
<p>Dave! (29:15.951)
Which, you know, those are tough odds.</p>
<p>Bryan! (29:21.104)
You really, really got to reach to sort of beat the champ in that one. Yeah. So we also get Laura Harrington, who is the love interest brat. You&#39;ve seen her in What&#39;s Eating Gilbert Grape and also Buckaroo Bonsai.</p>
<p>Dave! (29:34.178)
You&#39;ve seen her in a lot of things and you&#39;ve forgotten everyone.</p>
<p>Bryan! (29:37.428)
I can never remember her name. Ever. She&#39;s just there. And then rounding it out. We got a pre-Simpson&#39;s yard Lee Smith is Connie who screams her way through the entire movie.</p>
<p>Dave! (29:51.886)
She is, she, every line in this film, she shrieks. Every single one of them, every one of them. Oh, it is, ah.</p>
<p>Bryan! (29:55.94)
Curtis! Is he dead? Yep. She must&#39;ve been fucking a horse after every fucking day of this, because all she does is screech and shriek. There&#39;s a couple of people who are really swinging for the fences in this one. She is definitely one of them. I also, I love, I love, her and Curtis are my favorite characters in the movie.</p>
<p>Dave! (30:07.69)
Yeah, and she used that like on Eleven the whole time.</p>
<p>Dave! (30:14.463)
Yeah.</p>
<p>I kind of think they all are.</p>
<p>Dave! (30:22.998)
Yeah, because they&#39;re the only two that really understand what movie they&#39;re in. Don&#39;t forget Pat Hingle. You also get Pat Hingle in this.</p>
<p>Bryan! (30:26.776)
Yep.</p>
<p>Bryan! (30:30.816)
Oh, right. He&#39;s a Hendershot Bubba. But yeah, there&#39;s a lot of character actors in this because there&#39;s also Frankie. Frankie face on is in this.</p>
<p>Dave! (30:33.526)
Hendershot. Bubba Hendershot.</p>
<p>Dave! (30:40.258)
There are, because Frankie Faison is in it. Who else? Leon Rippey is in it. A guy from Deadwood. Somebody else is, somebody, Giancarlo Esposito shows up in it, very beginning.</p>
<p>Bryan! (30:45.636)
Yep.</p>
<p>Bryan! (30:51.404)
Right, right, right. He has, this is, yeah, my notes says a wild Giancarlo Esposito appears.</p>
<p>Dave! (30:56.266)
Yep. And there&#39;s also, I don&#39;t know if you watched the credits, I don&#39;t know why I did, but sitting through it, there is a Tabitha King plays woman number one and there&#39;s a character that is second woman played by one Marla Maples, the former Mrs. Trump.</p>
<p>Bryan! (31:06.852)
Ha!</p>
<p>Bryan! (31:13.976)
Oh, that&#39;s right. Yep. Moola Mooples. So, yeah, so some notes. This is this is adapted from King&#39;s short story, Trucks. Appears in his collect. It&#39;s yeah, it&#39;s actually I read it today while I was waiting. It&#39;s from the collection Night Shift. It&#39;s about 20 pages long, and it&#39;s one of the shorter stories in the book. Like the short The Lawnmower Man, the movie adds quite a bit.</p>
<p>Dave! (31:25.13)
Which, it should be said, is about eight pages long.</p>
<p>Bryan! (31:42.116)
to pad out the running time for feature, but unlike the Lawn Mower, man, it mostly sticks to the original themes.</p>
<p>Dave! (31:47.991)
And unlike the one more man, Stephen King didn&#39;t want to take his name off of this one.</p>
<p>Bryan! (31:54.345)
Yeah. So, uh, this movie feels obliged to explain itself with these.</p>
<p>Dave! (32:01.478)
Oh yeah, it is like, if shit&#39;s not blowing up, they are throwing exposition at you left and right. And when they&#39;re not doing that, they are just putting text on the screen.</p>
<p>Bryan! (32:07.417)
And it doesn&#39;t- it doesn&#39;t-</p>
<p>Right, so that&#39;s my next point is this movie has these two ridiculous book ended by these two utterly fucking ridiculous title cards that are just like, just, I don&#39;t know, maybe it&#39;s it reeks of producer notes. Like somebody was like, well, we have to explain what&#39;s going on because the novel, yeah, like the novel doesn&#39;t, it doesn&#39;t, it&#39;s not the novel, the short story. It starts off in the middle of everything and it</p>
<p>Dave! (32:28.618)
And they&#39;re like weirdly cutesy.</p>
<p>Bryan! (32:39.996)
ends on a real downer of a note and it not once ever says, oh, and there&#39;s a UFO, by the way. Like it does not do that. It just kind of thrusts you into it and sort of trusts you with it. This one, not so much. So if the 80s was the golden age of the Stephen King theatrical adaptation, then the 90s was the golden age of the made-for-TV adaptations, began with the objectively great adaptation of it and ostensibly ended with the objectively</p>
<p>bad adaptation of The Shining. Trucks was again adapted for television during this phase in 1987. It was USA. But rather than the adaptation being closer to the story, like many of these TV adaptations did, like this was the TV adaptations were like a second chance to kind of give Steve like the output that he wanted because I guess in one of his</p>
<p>Dave! (33:15.402)
That&#39;s a sci-fi movie, isn&#39;t it?</p>
<p>Dave! (33:34.134)
Yeah, he had a lot of control over the TV stuff.</p>
<p>Bryan! (33:36.504)
Yeah, like one of the motivations for doing this movie was literally when he says in the trailer, like if you want to do something right, you got to do yourself. He did object to the way that a lot of his movies were his novels were adapted to the screen. So when they went into him in the sort of in the 90s and Mick Garris was doing most of them and he was really tight with Mick Garris. So you got stuff like The Shining, which is way closer to the book. It just doesn&#39;t make for a very good TV movie.</p>
<p>Dave! (34:04.49)
And do you know the story about how he got that, the deal to do the Shining? Because Warner Brothers had to give their approval for it in terms of the licensing. And they said, okay, we will let this happen under one condition. And that condition was, he could no longer slander the original one. He could never talk about the original. Because he quite famously has spent a lot of time talking shit about how much he hates the Shining.</p>
<p>Bryan! (34:09.817)
I do not.</p>
<p>Bryan! (34:23.728)
Hahahaha</p>
<p>Bryan! (34:29.109)
He hates it!</p>
<p>Dave! (34:31.154)
And so that was the deal is that if you want to make this, we will let you do that. However, you can never say another thing about this. And he hasn&#39;t.</p>
<p>Bryan! (34:39.245)
I get the feeling that his objection to The Shining really rests on the fact that, I don&#39;t know, I don&#39;t read a lot of interviews. I&#39;m not terribly interested in what the guy has to say about his own stuff, but I get the feeling that The Shining, that Jack Torrance was a character that he felt was very close to himself, because he&#39;s a very, very...</p>
<p>Dave! (34:58.262)
Yes, he is. He isn&#39;t an author insert. It&#39;s exactly what.</p>
<p>Bryan! (35:01.688)
A very, very troubled guy. I get the feeling that was a book that he was working out some internal shit on. And so...</p>
<p>Dave! (35:09.634)
He really didn&#39;t like Jack Nicholson&#39;s performance, which I understand. I&#39;m not a Jack Nicholson fan. I think he plays the same character in everything. And that was what he really objected to, but he fucking hated it. So that&#39;s why he ended up getting that one. That movie is so goddamn bad.</p>
<p>Bryan! (35:24.216)
Yeah, yeah, it&#39;s in that it&#39;s during that time when like visual effects CGI was getting cheaper to do on TV, but the stuff that they had the budget for was so fucking shitty. And so like we got that we have the Langley ears where the VFX of that one are also really bad. Oh, yeah, the Langley ears is garbage. But yeah, so</p>
<p>Dave! (35:38.559)
And that story is terrible.</p>
<p>Dave! (35:43.53)
But you know, like, but Mick Garris was still, I think, a decent, he still is a decent director.</p>
<p>Bryan! (35:47.692)
He&#39;s done some great stuff. Like I&#39;m not a fan. I don&#39;t, like he does, he&#39;s done a lot of Stephen King stuff but he&#39;s a very capable director.</p>
<p>Dave! (35:55.734)
Yeah. Didn&#39;t he make one of the Critters movies?</p>
<p>Bryan! (35:59.704)
I think he did Critters 2. And also, he did one of the Masters of Horror. I can&#39;t remember which one, but I do remember when I got to it. I was like, oh, OK, because I was seeing it come up. Did he do the Richard Matheson one with Robert England? I can&#39;t remember. I&#39;ll have to look it up. Dance it, dance it. Yeah, yeah, that one. I&#39;ll have to look it up. But yeah, anyway, so this adaptation for USA.</p>
<p>Dave! (36:17.786)
The dance of death or whatever.</p>
<p>Bryan! (36:27.896)
where he was kind of redoing and sort of getting a chance to sort of like see his material adapted proper. This one is basically a fucking remake of Maximum Overdrive, but it&#39;s approximately 100% less fun. And also</p>
<p>Dave! (36:42.45)
Oh yeah, cause this movie, Maximum Overdrive is fucking terrible. This movie is so bad, but it is also one of the most fun movies you will ever see.</p>
<p>Bryan! (36:46.124)
unbelievably. It is so great. Yeah. So this follows a well established pattern of Stephen King&#39;s obsession with haunted automobiles. So he did it with Christine.</p>
<p>Dave! (36:57.45)
Yeah, there&#39;s like underlying everything here is this kind of weird fear of technology.</p>
<p>Bryan! (37:02.616)
Yeah, yeah, he did it with he did with Christine. He did it with from a Buick eight. I think this is sort of ghost car in riding the bullet.</p>
<p>Dave! (37:10.482)
And I mean, to be fair, it the 80s, the Cold War, I think, fucked with a lot of people in the idea of technology was really scary.</p>
<p>Bryan! (37:17.912)
Yeah. Well, also, I get that this is a real sort of boomer quality that I think is sort of tied to the fact that like, cars from the kind of like the golden age of manufacturing, they all sort of had character to them, the design, the way that they handled it, like it wasn&#39;t it wasn&#39;t like now, like today, cars are very ordinary, they have no character. Basically, like, there&#39;s no, you know, like, everybody used to get real kind of like caught up and like cat</p>
<p>Dave! (37:37.694)
Everything like everything else.</p>
<p>Bryan! (37:45.48)
is this like luxury auto but like you look at a Cadillac it&#39;s a fucking gigantic boat of a car there were</p>
<p>Dave! (37:51.926)
Yeah, Americans were sold on cars in order to like fund the Highway Expansion Act and to sell, you know, to drive at the price of oil. That&#39;s the reason why we don&#39;t have any like train infrastructure in this country. Why it&#39;s also bad is because I think much like guns, the government and I think kind of large corporations found a way to tie the automobile.</p>
<p>to the concept of freedom, which is then tied to the concept of this idea of the American identity. And that&#39;s why there&#39;s so much resistance to trains and buses in general, but it&#39;s what you&#39;re talking about. It&#39;s that idea of like, it&#39;s a unique, cool thing that&#39;s a part of us, it&#39;s a part of our history. And it&#39;s like the fucking car.</p>
<p>Bryan! (38:38.06)
Yeah, well, I mean, even I&#39;ve got cars that I&#39;m like into, and I&#39;m not like a car guy, but like, there are certain cars that, thanks to the way that they&#39;re kind of portrayed in movies and on TV, like I&#39;m a real big fan of the Dodge, like the Bopars, so the Charger and the Challenger, and my Challenger obsession mostly goes back to, no, that was the Mustang. Oh, is it? Okay. The, fuck.</p>
<p>Dave! (38:56.59)
Steve McQueen.</p>
<p>That&#39;s a charger. That charger is from Bullet. Yeah.</p>
<p>Bryan! (39:07.28)
Vanishing point is the one that has the white challenger that after that movie. I was like I gotta fucking get that car So like I mean it definitely has an effect on me But like you can see it it&#39;s even it&#39;s a big character in this movie where he even puts a fucking face on one of the trucks in here so like There&#39;s something about like the characteristics that make these cars sort of like really something to him like</p>
<p>Dave! (39:29.334)
Weirdly though, it&#39;s trucks, it&#39;s not cars. It&#39;s never cars.</p>
<p>Bryan! (39:34.498)
True. Because they&#39;re big and they&#39;re scary and they&#39;re noisy and they&#39;re big.</p>
<p>Dave! (39:37.65)
It&#39;s like every else comes to life in the in this movie. Spoiler alert, that&#39;s the premise of the film. Electronic things coming to life, except not cars.</p>
<p>Bryan! (39:49.356)
Right, because yeah, it&#39;s, the movie&#39;s a little fucking hazy on the rules.</p>
<p>Dave! (39:53.194)
Oh yeah, there&#39;s no, don&#39;t ask for details. The details are not important here.</p>
<p>Bryan! (39:55.532)
Yeah, just know this. You&#39;re OK if you&#39;re driving a car. But if you&#39;re driving a truck, you&#39;re fucked. So.</p>
<p>Dave! (40:01.866)
Also, perhaps these trucks can hear. And they can certainly see. Yeah, they understand human emotions. Because these trucks, they&#39;re a bunch of fucking jerks.</p>
<p>Bryan! (40:04.408)
This is true. And they can communicate as we come to find out.</p>
<p>Bryan! (40:13.092)
Yep, big time assholes. So the movie opens on a shot of the earth from space with a strange green glow about it. A little title card announces that a comet would pass near enough to the earth to put the planet in its tail for the next eight days.</p>
<p>Dave! (40:28.038)
Comets were really fucking hot in the 80s. People were really excited about that shit.</p>
<p>Bryan! (40:29.932)
So Night, well, do you know why? Because in 1986, Halley&#39;s Comet came really close to the earth, yeah. So, and like there&#39;s a couple of movies at the time that really kind of cashed in on that life force. Famously, there&#39;s a Comet that has a whole fucking spaceship full of vampires, naked vampires, no less. Night of the Comet was another one. Oh yeah, where are there&#39;s, it&#39;s, you know what?</p>
<p>Dave! (40:34.158)
But it&#39;s coming, yeah.</p>
<p>Dave! (40:49.29)
Yeah, you get also get night of the comment. And if you think you&#39;re going to get out of not watching that one, you are very wrong because you are going to watch that film.</p>
<p>Bryan! (40:57.056)
It&#39;s just a ticket clock at night of the comet for us. Yeah, comets were all the rage in the mid 80s because I. Yep, yep, make no mistake. It was also like the golden age of the space shuttle until, unfortunately, until unfortunately, 1986. Yeah, so we cut to the exterior of a bank in North Carolina. It&#39;s electronic marquee switches from displaying the time and weather to a flashing message, which simply reads, fuck you.</p>
<p>Dave! (40:59.414)
Yeah, I love that movie.</p>
<p>Dave! (41:03.692)
Comets in cars, man.</p>
<p>Dave! (41:11.566)
I don&#39;t know until... Yeah.</p>
<p>Dave! (41:26.122)
Which I just, you gotta wonder, how do they know? How do the aliens know that is offensive? And also, I love that it just says fuck you. Like to no one in particular.</p>
<p>Bryan! (41:33.486)
I know.</p>
<p>Bryan! (41:39.232)
Everybody, everybody in Wilmington, North Carolina. And a gentleman played by Stephen King, dressed like an extra, dressed like an extra from a Weird Al video, approaches the ATM here, and the machine calls him an asshole. And he&#39;s like, hey, honey, you gotta come over here. This machine called me an asshole.</p>
<p>Dave! (41:45.552)
There&#39;s that fucking mug again.</p>
<p>Dave! (41:50.08)
Yeah.</p>
<p>Dave! (42:00.034)
You know what I love about Stephen King? I like the fact that he is a terrible actor. He pretty much knows it, but he does not let that stand in his way.</p>
<p>Bryan! (42:06.756)
does, does not give a fuck because he looked at the mirror after doing an entire eight ball by himself and said, you got this Steve.</p>
<p>Dave! (42:16.81)
Yeah, this movie is basically the end of Scarface. Like, that is how this happened.</p>
<p>Bryan! (42:22.705)
So, uh, now the titles for the movie come on and they play over cars passing over a drawbridge.</p>
<p>Dave! (42:29.642)
I have to tell you, this opening to this film, it is only rivaled by Sleepaway Camp as the greatest opening to any movie ever. It does not make any sense. It is so crazy and it goes on for like eight fucking minutes.</p>
<p>Bryan! (42:32.412)
It&#39;s just...</p>
<p>Bryan! (42:40.124)
It&#39;s so fucked up. So nope. Nope. It&#39;s so long. It&#39;s like 10 minutes of the movie. So the operators are dopey shitheads that seem really common among like horror movies of the eighties. Like this is, this is, this movie is like one step away from a trauma movie where there&#39;s a guy who is like puts, you know, we&#39;ll like sniff his own farts or something like that. It&#39;s very, it&#39;s got some real low brow comedy.</p>
<p>Dave! (43:06.806)
Yeah, this is an extremely juvenile movie.</p>
<p>Bryan! (43:09.444)
Yeah, one of the guys is like three knuckles deep up his nose and they&#39;re playing cards. And then the bridge begins to operate itself, kicking off one of the most demented sequences ever committed to film.</p>
<p>Dave! (43:21.398)
Because it is so slow. They, the action that is happening in the scene as the bridge opens, there&#39;s lots of people on the bridge in their cars and everyone&#39;s like, oh, what? And like, and then the, and then like some guy slides off of his motorcycle and just plummets into the ocean.</p>
<p>Bryan! (43:24.418)
It&#39;s-</p>
<p>Bryan! (43:32.506)
Oh no! Ah!</p>
<p>Bryan! (43:39.485)
into the into the river. Yeah. So the breaches.</p>
<p>Dave! (43:41.278)
And the more the bridge opens, the things start to fall, but everything&#39;s falling in really slow motion. But it&#39;s falling at a rate that is impossible. Like the.</p>
<p>Bryan! (43:50.192)
This is the thing, so there is a scene where a car slides very gently into another car and the fucking woman driving it flies through the, through the, straight through the windshield, presumably dying, there&#39;s, let&#39;s, hang on, let&#39;s, hold on, let&#39;s, let&#39;s put a pin in the water balance, cause that&#39;s,</p>
<p>Dave! (44:00.846)
straight through the windshield.</p>
<p>Dave! (44:06.07)
Yeah, the watermelon, there&#39;s a truck of watermelons, and then the watermelons start falling at some velocity that seems not right.</p>
<p>Dave! (44:16.574)
My note, because my note just says watermelons. You know what to do.</p>
<p>Bryan! (44:21.125)
So the bridge is slowly rising and traffic stops and not one of these fucking morons thinks I should probably get out and walk to safety.</p>
<p>Dave! (44:29.226)
Yeah, there&#39;s a lot of all you need to do is step 10 feet to your right in this movie. Like, every death is easily avoidable.</p>
<p>Bryan! (44:37.243)
A truck is stopped like directly on the seam of the bridge and the driver actually gets out and looks and considers getting down and walking away before he&#39;s like, nah, I can drive this out of here. And then, and then no, he doesn&#39;t because the bridge like eventually like lifts to such a point that the fucking entire rear axle breaks off and rolls away. And then the truck falls into the into the river too. But but there&#39;s people.</p>
<p>Dave! (44:48.918)
He doesn&#39;t. He does not.</p>
<p>Bryan! (45:01.66)
falling out of the cars, there&#39;s people, there&#39;s motorcycles sliding just everywhere, there&#39;s people crashing into one another very gently. And then they are, and then everybody is just violently sailing all over the place. And then watermelons start flying everywhere. Cause there&#39;s a truck full of watermelons everywhere. And it&#39;s not just that they&#39;re flying everywhere, people see them coming at them and react like they&#39;re about to be hit by a fucking asteroid.</p>
<p>Dave! (45:09.138)
It is anarchy. It is pure anarchy.</p>
<p>Dave! (45:31.614)
And you know that writing this movie, he was just like in a frenzy, just typing, wildly typing, just foaming at the mouth, and then he goes, ah, watermelons!</p>
<p>Bryan! (45:36.754)
Oh, Jesus Christ, he wrote.</p>
<p>Bryan! (45:42.472)
Just all over the place causing total pantomone. This is one of my favorite scenes of all time. So this is the second time I&#39;ve watched this movie in a couple of months. There was a period where I was on a work trip to Los Angeles and my flight got bumped and so I had a ton of time to kill while I was calling the airline and hotels and shit.</p>
<p>I was like, you know what? I&#39;m gonna watch Maximum Overdrive in a while. Like I should put that on. And this scene came on and I was like, why have I not watched this movie sooner? Like, why has it taken me this long to come back to this one? Cause I&#39;m like, I have avoided this movie for a very long time because my memories of it are not good and clearly.</p>
<p>Dave! (46:22.478)
I think until recently I had not seen this since because I remember this is one of the movies that I remember was on WLVI all the time. Like this was one that ran a lot.</p>
<p>Bryan! (46:30.964)
all the time. Yeah, it was that and like we like we&#39;d cable at the time. And I remember it playing a lot then because the ads ran a lot. This was a very heavily marketed movie, as I recall. And like I remember seeing scenes of just yeah, like the trucks like driving in circles around the truck stop and being like really kind of troubled by it. Like something about that when I was like very young.</p>
<p>Dave! (46:42.978)
Well, you&#39;re gonna need it.</p>
<p>Dave! (46:50.402)
Well, I remember, because I remember being really upset about this because, I mean, I, you know, the first time I saw it, I was probably nine or 10, because it would have been in the 80s.</p>
<p>Bryan! (46:56.92)
Like if I was yeah if I was 12 yeah like if I was I think it was like 12 or 13 when this came out you would have been about like six or seven so yeah</p>
<p>Dave! (47:03.01)
I would have been six or seven. So yeah, I remember being really upset by it, not the part at the truck stop, it&#39;s the part, well, you&#39;ll get there eventually, but when the kids are getting killed, I remember that being very upsetting to me as a child. And now, watching it now, it is fucking hilarious.</p>
<p>Bryan! (47:15.92)
The kit, yeah, that&#39;s, it&#39;s not something, it&#39;s awesome. But yeah, back then, like they didn&#39;t do that in movies. And so as a child, you&#39;re like, holy shit, they&#39;re out to get us. Yep, so we cut to the Dixie Boy truck stop. And the first thing we see is the iconic happy toys truck. And it&#39;s the one thing that everybody recognizes in this movie, it&#39;s on the fuck in front of every VHS and DVD, Blu-ray box. It&#39;s,</p>
<p>Dave! (47:28.222)
Oh yes, Steve&#39;s gonna take you there.</p>
<p>Bryan! (47:45.424)
This is the truck with the Green Goblin face on it. They must have. You can&#39;t just do that. They would have had to like. Yeah.</p>
<p>Dave! (47:48.782)
Did you think they had to pay for that?</p>
<p>Cause there&#39;s a lot of like product placement in this and I wonder if they, if it was like consensual or they&#39;re just like, we just need fucking trucks and trucks have brands on them.</p>
<p>Bryan! (48:01.164)
You know, the thing with the thing is, is like I&#39;m a comic book fan going a ways back and like I read, um, it&#39;s called Marvel Comics, the untold story. And it really, it&#39;s a, it&#39;s a exhaustive history of Marvel Comics. And this is during that period where Stanley was out there basically giving the Marvel license away to whoever, whoever.</p>
<p>Dave! (48:20.342)
Oh yeah, because this is when you get those, you get the Fantastic Four movie that never came out, you get the Captain America movie that never came out. This was around that same time. Well, this would have been years earlier.</p>
<p>Bryan! (48:25.336)
Yep. Yeah. So like, I guarantee I guarantee. Diller Edges wrote a check for like 100 bucks and Stanley was like, Yes, I&#39;m in movies now. So because yeah, because I mean, it&#39;s the Green Goblin. Like if you know, you don&#39;t gotta know you don&#39;t gotta read those comics to know that face. Like it&#39;s one of those like</p>
<p>Dave! (48:44.33)
Yeah, he gave him $100, he hugged Stan Lee very enthusiastically and invited him back to the house. Because that seems like that&#39;s the kind of guy he was.</p>
<p>Bryan! (48:50.04)
Yep, Stanley. Yes, Stanley, he was in love with show business and said he really, really wanted to be involved in it. And I guarantee he told people for years that him and Stephen King were tight bros. Yeah, but yeah, this is a this is a really great way to sort of punctuate and sort of put a character to an otherwise like. Uncharacterizable villain. You got to give.</p>
<p>Dave! (49:03.299)
Yeah.</p>
<p>Dave! (49:15.702)
Yeah, you have to anthropomorphize this because otherwise there&#39;s nothing threatening about it. Unfortunately, they don&#39;t use this image as much as people probably remember they did or think they did. Because this is not really in it that often.</p>
<p>Bryan! (49:20.506)
Right.</p>
<p>Bryan! (49:26.652)
Uh-huh. It&#39;s not yet. It&#39;s definitely, definitely not as prominent as I certainly remembered it being the big menacing thing. When I came back to it, I was surprised by how little that truck is in it.</p>
<p>Dave! (49:41.814)
Driven by Frankie Faison of The Wire and Silence of the Lions.</p>
<p>Bryan! (49:43.896)
Yep, yep. Frank and yeah, like all of the Hannibal Lecter movies. So, yeah, so. The driver, this is Frankie Faison, his name is Handy, which I don&#39;t know what that is short for. I could not. Who cares? Here&#39;s the thing. It&#39;s Frankie Faison. So he stops for gas, he heads into the diner, which is where we meet Wanda, the waitress who&#39;s going to.</p>
<p>Dave! (49:59.646)
Doesn&#39;t matter, you&#39;re not gonna need to know it. You&#39;re never, they&#39;re never gonna say it again.</p>
<p>Yeah, you&#39;ll know when you see him.</p>
<p>Dave! (50:12.894)
I, this is the greatest character in this movie. She is phenomenal.</p>
<p>Bryan! (50:15.608)
She is off the fucking hook from the start. Yep. And we also meet Bill, who&#39;s our, our stock blue collar, Stephen King, every man.</p>
<p>Dave! (50:26.158)
He really... This is the same fucking character from Graveyard Shift. It&#39;s this...</p>
<p>Bryan! (50:31.402)
Oh, and fucking the mist and...</p>
<p>Dave! (50:34.418)
Yeah, it&#39;s working class, like blue collar, working class hero type who has gone to college though, because that&#39;s very important that he&#39;s educated. He&#39;s educated, but he&#39;s not arrogant. And you know that because he works at a truck stop. These are like, these are more of like Stephen King&#39;s weird hangups that he has about class.</p>
<p>Bryan! (50:41.033)
Right! Right.</p>
<p>Bryan! (50:53.656)
Yeah, yeah. And so back in the game room, this is where we find Giancarlo Esposito, which I built. I think this might have been like either his first role or one of them because he doesn&#39;t really he doesn&#39;t he&#39;s got some lines, but they&#39;re really there&#39;s not much to it. He appears in the game room is all the machinery in the game rooms going nuts.</p>
<p>Dave! (51:01.472)
our last video.</p>
<p>Dave! (51:05.535)
It&#39;s pretty early.</p>
<p>Dave! (51:16.89)
What was his big was he in stand and deliver?</p>
<p>Bryan! (51:19.672)
He was in do the right thing. Right? So like all shits going crazy glass on one of the pinball machines breaks and we cut to a close-up of him. We&#39;re like spits yo mama at the at the pinball machine for some fucking reason. But yeah, we cut over coins and cigarettes come flying out of the cigarette machine is coins going everywhere and he proceeds to stuff every.</p>
<p>Dave! (51:22.263)
That&#39;s right.</p>
<p>Bryan! (51:49.308)
pocket under his hat. Everything with whatever he can get his get his hands on now outside of the gas pump. The attendant Duncan is pumping gas on the happy toys truck. We get a look at the side of the truck which reads here comes another load of joy.</p>
<p>Dave! (52:08.818)
Yep. Yeah. It&#39;s, it&#39;s, it&#39;s just the beginning. We&#39;re going up the hill on the roller coaster right now. Because there will be fart jokes coming.</p>
<p>Bryan! (52:17.574)
Yeah.</p>
<p>Bryan! (52:21.129)
And the-</p>
<p>Bryan! (52:24.932)
Yeah, nope. The pump stops pumping. So like a fucking jabroni, he takes it out. He looks down the barrel of this pump gets a face full of fucking diesel which blinds him.</p>
<p>Dave! (52:24.991)
And that&#39;s not all.</p>
<p>Dave! (52:35.914)
And he reacts like he just got shot in the face. He is howling and screaming. I&#39;m sure it burns and is probably deeply unpleasant.</p>
<p>Bryan! (52:39.232)
sure getting I&#39;m sure getting I&#39;m sure getting gasoline than the yeah getting gasoline than the face probably sucks but like you would think that he sees like he just got shot with a flamethrower yeah so this is where</p>
<p>Dave! (52:51.339)
Yeah.</p>
<p>Like everyone, everyone is just fucking doing it for the back rows on this movie.</p>
<p>Bryan! (52:58.052)
No, no, this is and this is where we first hear like this is the only it&#39;s I wouldn&#39;t really call it music. It&#39;s just it&#39;s a an audio sting that they use whenever the machines are doing something villainous. Yeah. Yeah, it&#39;s and it&#39;s great. Yeah, it&#39;s basically it&#39;s fucking the shower scene. It&#39;s Bernard Herman again. Like everybody rips this off because it&#39;s great. It works. It really.</p>
<p>Dave! (53:10.302)
and it&#39;s super recognizable. It&#39;s been used in other things.</p>
<p>Dave! (53:22.686)
If you&#39;re going to rip someone off, rip off Bernard Herman. He was really good at what he did.</p>
<p>Bryan! (53:26.456)
Yep. So, uh, yeah, it gets the job done. I, I love it. And, uh, we cut to the, uh, the boss, Bubba Hender shots office. We learned it.</p>
<p>Dave! (53:35.07)
Now, my question is, is his name Bubba? Because he also calls everybody.</p>
<p>Bryan! (53:40.42)
He calls everybody Bubba and at one point when he&#39;s on the radio later, he says, this is Bubba Hendershot. But I just very, very weird. Yeah.</p>
<p>Dave! (53:46.25)
Yeah, OK, I think that is a very odd touch. Like that is your name, but you also call other people by your name.</p>
<p>Bryan! (53:55.288)
Yeah, it&#39;s like the iron cheek. Everybody&#39;s baba to him. We learn that he&#39;s. Ah man, fucking poor went out for the iron cheek. He was the best. Yeah, so we learned that Bill&#39;s boss Hendershot is a crooked piece of shit in the way that Stephen King does. It&#39;s it&#39;s he&#39;s got one of these guys and every one of his stories.</p>
<p>Dave! (54:00.566)
I wish the Iron Sheik were in this movie.</p>
<p>Dave! (54:05.367)
Yeah.</p>
<p>Bryan! (54:17.956)
He&#39;s a one dimensional jerk who wants Bill to work nine hours for eight hours pay. He has a holdover bill because he&#39;s out on parole and his hendershot says, you belong to me or you belong to the state of North Carolina.</p>
<p>Dave! (54:29.666)
which I&#39;m pretty sure is exactly the same plot as Graveyard Shift. I think it&#39;s the same thing in that movie. That movie is fucking garbage.</p>
<p>Bryan! (54:35.676)
I yeah, he that&#39;s yeah, that&#39;s the thing. But that&#39;s another thing about King is he tends to recycle stuff like I feel like the dark half. There&#39;s another and there&#39;s another story.</p>
<p>that was adapted in the 2000s, I think. I think it was the one with Johnny Depp, which was this secret window. Same fucking story as the dark half. Yeah, so like he has a tendency to recycle shit in his own stories. I don&#39;t know. People will fucking buy anything. He&#39;s probably just got filing cabinets full of fucking manuscripts that like he doesn&#39;t even remember writing. Like I believe Pet Sematary was</p>
<p>Dave! (54:57.323)
Oh, secret garden, secret window or something like that. Secret window, secret garden. Yep.</p>
<p>Dave! (55:09.934)
It&#39;s because people will buy anything.</p>
<p>Bryan! (55:21.496)
was one that he just had kicking around and he ended up like submitting it to a publisher in order to get out of a fucking contract with him. So like, yeah, apparently he&#39;s just got shit kicking around in like different states of draft and</p>
<p>Dave! (55:35.318)
Yeah, and he&#39;s like, it&#39;s like Mondo, like you just slap the name on it and people will buy whatever it is. It doesn&#39;t matter.</p>
<p>Bryan! (55:40.993)
Yeah, yeah, then they&#39;ll sell it for thousands of dollars on eBay. But yeah, this is a big setup so that we can feel really great about his inevitable death. When I saw this guy, I thought of Marsha Gay Hardin&#39;s character in the mist. She&#39;s that like religious zealot who it&#39;s just like, oh, she&#39;s going to fucking die. Yeah, yeah, but.</p>
<p>Dave! (56:01.098)
Yeah, another stock character. Like it&#39;s someone we can build up to the point where you absolutely hate them, so you will experience glee when they die.</p>
<p>Bryan! (56:10.028)
Yeah, yeah. So back in the game room, Esposito has cigarettes and coins stuffed everywhere. I guess becomes enraptured by the weird shapes and sounds on the video game screens, which I noticed it&#39;s, I can&#39;t remember what the name of that deck of cards is, but it&#39;s those symbols they use when they&#39;re like testing ESP, like what&#39;s the shapes? Zen or cards, that&#39;s the one, it&#39;s that. And so I think it&#39;s supposed to be like hypnotic or something because it&#39;s like psychic and shit, but he becomes like enraptured. Yep.</p>
<p>Dave! (56:28.712)
Oh, Zenner cards.</p>
<p>Dave! (56:36.554)
It&#39;s just working that T. You gotta get that TK in there, man.</p>
<p>Bryan! (56:40.528)
He becomes enraptured by this thing and he becomes compelled to touch the video game, which results in a fatal shock. Sends a flying and a shock to death somehow. But I will say this, I used to work in a pizza and video games place for children. And if those video games are not grounded properly, you will touch certain parts of them and get quite a surprise. I have been shocked so many fucking times by video games.</p>
<p>So meanwhile, back in civilization, we meet Deke, which I fucking love that name. I don&#39;t, it must be like, this is the little kid. That must be short for like Deacon or something like that. But all I think, when I think of Deke, I think of fucking Strongbad and that like basketball player character that he&#39;s always talking about. The Deke was standing at the stop of the key and I&#39;m all like, I&#39;m open. That&#39;s all I think about whenever I hear that name. But he&#39;s a.</p>
<p>Dave! (57:16.226)
Oh, is this the kid? He&#39;s a... I think so.</p>
<p>Dave! (57:35.874)
Uh, he&#39;s a, he&#39;s a very strange looking boy. Like he has this kind of odd androgyny that he&#39;s like, it&#39;s, it&#39;s like, every time they focus on him, I&#39;m like, ah, you&#39;re kind of creepy looking.</p>
<p>Bryan! (57:42.605)
Yeah.</p>
<p>It&#39;s hard to tell how old he is.</p>
<p>Bryan! (57:51.585)
Yeah, yeah, he&#39;s at his league Little League game. They win. The coach treats the kids to sodas from a nearby machine and pays dearly for his generosity when the machine fires cans at him like a fucking machine gun because it&#39;s Stephen King. Because it&#39;s a Stephen King movie in the 80s, he has a hot. Yeah, he ultimately gets beamed in the head, and I really, really wish that they had seen fit to go all the way with this and just like him.</p>
<p>Dave! (58:02.826)
And the first one hits him right in the nuts.</p>
<p>Because it&#39;s like a 12-year-old on coke wrote this movie.</p>
<p>Bryan! (58:18.468)
bedded a can like in his head like in that movie fucking idle hands where the guy&#39;s got the bottle.</p>
<p>Dave! (58:22.646)
I mean, because they kind of do like the imprint is there. This is everything. And this is practical effects. There&#39;s no nothing digital.</p>
<p>Bryan! (58:28.068)
Yeah. Nope, nope, way before, way before that. But yeah, so the cans start fucking flying. We cut to this like wide shot of all the baseball players like standing and the machine is just spitting. It&#39;s like there&#39;s probably like production assistants standing off, just off frame, throwing cans at children. But they&#39;re like wildly flying everywhere. This kid&#39;s getting hit and they get knocked over.</p>
<p>Dave! (58:50.326)
Yeah. And my note says there&#39;s nothing better than kids getting attacked by a soda machine. It&#39;s fantastic.</p>
<p>Bryan! (58:55.932)
That&#39;s one of the funniest scenes in the movie. Yeah, but out in the outfield, the kid goes ass over handlebars for some reason, like this absolutely... ..</p>
<p>Dave! (59:02.71)
This is another example of just like, just dude, just get up and move. But he doesn&#39;t. It&#39;s like that Austin Powers scene where the guy just stands there as the thing. It&#39;s that.</p>
<p>Bryan! (59:07.18)
Yeah, yep, that&#39;s all you gotta do.</p>
<p>Oh, my God.</p>
<p>Oh yeah, that scene. But yeah, I think that&#39;s why I like Kurt and Connie because they&#39;re the only characters in the movie who are like, I can just jump out of the way instead of doing what everybody else does when a truck is bearing down on them and turning and running away.</p>
<p>Dave! (59:29.43)
Because every person in this movie was waiting for someone to direct them. Kurt and Connie are the only ones who are like, I guess we&#39;re on our own. Let&#39;s go, baby.</p>
<p>Bryan! (59:39.589)
They really, they&#39;re an island unto themselves and they are surviving.</p>
<p>Dave! (59:41.79)
Like they get it, they know what movie they&#39;re in.</p>
<p>Bryan! (59:45.432)
Yeah, but the kid goes flying, you know, into the things off his bike. He&#39;s laying there and then fucking there is a he&#39;s right in front of the scoreboard and then a fucking steamroller comes like flying at like 100 miles an hour through the thing rolls over the kid. We catch a glimpse of. Yeah, that also I used to think that was kind of a bummer. Nowadays, I think it&#39;s hilarious because we get a fucking glimpse of him going under the big like steamroller wheel. So apparently the original cut of this movie</p>
<p>Dave! (01:00:00.394)
And it was that scene, it was the steamroller that used to freak me out.</p>
<p>Bryan! (01:00:15.28)
They set up this effect where it was supposed to be like a Bugs Bunny kind of thing where the thing goes over him and there was like a blood bag set in the outfield so that it would ride over it and then get it on the wheel and then ride across the baseball field with this big streak of blood behind him. But that&#39;s not really how shit works. So it rolls over this blood bag and the fucking stuff went everywhere. Like every drop of blood in the kid just came out. Apparently Stephen King was like,</p>
<p>awesome, I don&#39;t give a shit, that&#39;s the best, cut it, print it. This is the thing. It was one of the things they had to cut for an R rating because apparently this movie was way, way more violent than the movie that we got. And I want to see it also. Apparently those cuts have been lost and we will we will never see them. But there&#39;s a lot of little like two seconds, three second cuts that.</p>
<p>Dave! (01:00:47.018)
Yeah, why didn&#39;t they put that in? Why would they not leave that?</p>
<p>Dave! (01:00:57.983)
I wanna see that one.</p>
<p>Dave! (01:01:08.886)
Damn you, Vestron.</p>
<p>Bryan! (01:01:10.368)
Oh, Jesus Christ, I know. But like their little bits, like they, it was basically a death by a thousand cuts for this movie. So there&#39;s a lot of stuff that didn&#39;t get in. There&#39;s just some story about like it made Romero sick when they watched the dailies. I don&#39;t fucking buy it. Nothing makes that guy sick. I mean, have you not seen Day of the Dead? Jesus Christ. Yep, yep. So use your imagination on this shit. So next we meet Brett, who&#39;s a hitchhiker and the rapey Bible salesman Loman.</p>
<p>Dave! (01:01:23.24)
I doubt that.</p>
<p>And Day of the Dead.</p>
<p>Choke on them.</p>
<p>Bryan! (01:01:38.876)
who are driving down the highway in a car that is for some reason unaffected by what&#39;s happening as we come to find out cars are fine.</p>
<p>Dave! (01:01:45.406)
And Brett, she&#39;s the one, Laura, whatever, right? She has got the weirdest fucking energy in this movie. Like, I don&#39;t know what she&#39;s doing, but she is, she is a strange character.</p>
<p>Bryan! (01:01:48.27)
Laura Harrington, yeah.</p>
<p>It&#39;s all over the place.</p>
<p>Bryan! (01:01:56.34)
Yep. She he keeps the driver Loman is he keeps putting his hand on her leg until she threatens to cut his hand off. And then that&#39;s when they roll into the Dixie Boy, very vigorous exchange of words out on the parking lot when</p>
<p>Dave! (01:02:10.678)
Yeah, which all seems really inappropriate, because it&#39;s like she grabs the wheel and pulls him to the thing, and then she jumps out of the car. He like chases after her, but it&#39;s like, you just met her. You don&#39;t give a shit about her or why she wants to get out of your car. What is this scene?</p>
<p>Bryan! (01:02:24.516)
Yeah. He&#39;s another again, he&#39;s another one of those characters that appears in a lot of fucking Stephen King stories, where it&#39;s the fake Christian, there&#39;s a lot of those guys, he&#39;s a guy because this is a Bible salesman, you see him pitching people in the truck stop a little later on. But it&#39;s it&#39;s, it&#39;s bullshit, because he&#39;s basically he&#39;s got his fucking hands all over this woman who he picked up.</p>
<p>Dave! (01:02:38.14)
Oh yeah.</p>
<p>Bryan! (01:02:50.584)
And then when she like rebuffs him, he gets out and he&#39;s like, you need like you. How dare you talk to me like that? And then like for some reason, like this is when the truck starts up, the toy truck and just about backs over, but she sees a common yank some out of the way. Um, now we meet Curtis and Connie just married. My favorite characters in the movie. They are so great. So she, like I said, she&#39;s, she.</p>
<p>Dave! (01:03:10.166)
Ooh. She is so obnoxious.</p>
<p>Bryan! (01:03:16.492)
spends the entire fucking movie screaming. And I think the first shit that she says is like at the top of her lungs. They&#39;re in like a just married, the car&#39;s all decorated and shit, but they need gas. And she said something about like, oh, I&#39;ve got to pee. And this is the first of two parts where Curtis displays an uncanny enthusiasm.</p>
<p>for watching her pee. There&#39;s another line a little later on.</p>
<p>Dave! (01:03:48.438)
Yeah, there&#39;s a lot of weird interaction between them that is just so great.</p>
<p>Bryan! (01:03:52.432)
Yeah, he&#39;s like, hey, you mind if I watch it? She&#39;s like, get the fuck away from me. Like, this might be like something that he is just revealing to her now that they&#39;re married.</p>
<p>Dave! (01:04:01.066)
And the weird thing about these two is that nobody in this movie has chemistry except for them. Like they know what they&#39;re doing. They get it. Like I don&#39;t know if like Stephen King like you can direct two people and he was like, okay, you two and then everybody else is on their own because they really know what they&#39;re doing.</p>
<p>Bryan! (01:04:06.72)
Except for them. They&#39;re the best.</p>
<p>Bryan! (01:04:15.672)
Yeah. Yeah, yeah. Because a little later on, like they kind of clumsily thrust Brett and Bill together because we got to have.</p>
<p>Dave! (01:04:24.39)
in the most awkward, like unrealistic way. But that&#39;s again, that thing Stephen King does where it&#39;s like people in peril are gonna take a break, just some time out to fuck each other. Like that&#39;s not gonna happen. That&#39;s not a thing you&#39;re gonna do.</p>
<p>Bryan! (01:04:27.769)
Yeah, like.</p>
<p>Bryan! (01:04:36.461)
Yeah.</p>
<p>Bryan! (01:04:39.808)
Yeah. Right. But like, I don&#39;t we&#39;ve got a character. We&#39;ve got a pair of characters who is whose relationship is romantic. It makes sense. It&#39;s realistic. I don&#39;t know why they felt like they needed to do it with our like our. And maybe it&#39;s just because he&#39;s the hero of the story. He&#39;s got to get a piece. It&#39;s just it&#39;s clumsy. It&#39;s fucking bullshit. It doesn&#39;t make any fucking sense. And I don&#39;t I don&#39;t I don&#39;t buy it for a second, but they stop.</p>
<p>to get gas, they find a dead body at the first station that they stop at. And then while he&#39;s sort of like inspecting it, this is where she&#39;s like, Kurt, is he dead? Which all of her shit is delivered with a ridiculous accent. And this is when they come under a track by Franklin&#39;s tow truck from GTA five. Yeah. And it crashes as he and this is the moment where it comes at him. And instead of turning to run, he does the fucking logical thing and just steps aside and it crashes. And, you know, it&#39;s</p>
<p>Dave! (01:05:16.364)
Yeah, it&#39;s.</p>
<p>Dave! (01:05:26.198)
Mm-hmm.</p>
<p>Bryan! (01:05:36.268)
incapable of chasing them.</p>
<p>Dave! (01:05:38.902)
But then he&#39;s like, he&#39;s laughing as well. He does a lot of weird, like at least he makes a lot of weird choices with this character that don&#39;t make sense, but it&#39;s kind of like, there&#39;s something so awkward and charming about him that it&#39;s kind of like, I just like watching you, like kind of stumble around flailing and then laughing. And then like doing this kind of weird jaunty run.</p>
<p>Bryan! (01:05:45.33)
Yeah.</p>
<p>Bryan! (01:05:50.617)
Yeah.</p>
<p>Bryan! (01:06:01.272)
Yep. So now we get to the part where, like we just talked about, where Bill and Brett are kind of thrust together. They&#39;re outside. They&#39;re like, Whoa, that was crazy, huh? Like remarking on this truck that&#39;s taken off by itself. They just apropos with nothing. She says, you&#39;re cute. He&#39;s like, huh? Huh? You think?</p>
<p>Dave! (01:06:19.606)
Yeah, and it&#39;s so there&#39;s nothing authentic about it. There&#39;s it just feels so like, you know, gross.</p>
<p>Bryan! (01:06:25.536)
It&#39;s very sudden and abrupt. There&#39;s no way to sort of build towards this. They just don&#39;t have fucking time. So they were like, here, you two are into each other.</p>
<p>Dave! (01:06:33.854)
and everybody is just high as hell. Like they&#39;re just, he looks like Joe Spinell in Maniac. He&#39;s just like sweating. His eyes are all fucking glassy and red. I&#39;m like, he&#39;s a good looking guy and he looks like hell through this whole movie. Just looks like he&#39;s about to have a heart attack.</p>
<p>Bryan! (01:06:37.3)
Yep. Yeah.</p>
<p>Bryan! (01:06:43.165)
maniac.</p>
<p>Bryan! (01:06:47.941)
Yeah.</p>
<p>Bryan! (01:06:52.525)
I hope.</p>
<p>Yeah, and so this is when we cut back to town as Deke rides around his neighborhood in a radio broadcast. Kind of plays over this, it gives us a sense of scale that this is... Yeah, this is, just so you all know, this is happening everywhere. And there&#39;s dead bodies all over the neighborhood. For some reason there&#39;s a... It is. For some reason there&#39;s a dog that&#39;s been killed by a radio-controlled police car. I would like to know how this works.</p>
<p>Dave! (01:07:03.626)
Yeah, they&#39;re like, here you go, here&#39;s some more, here&#39;s some more exposition.</p>
<p>Dave! (01:07:13.046)
This part is so awesome.</p>
<p>Dave! (01:07:22.918)
I would like to know how the guy got killed by his Walkman.</p>
<p>Bryan! (01:07:26.724)
Was Walkman like, how did it do this? I mean, as we find out, like the stuff, like the machines could kind of move around on their own, which, but like, yeah, this list, she&#39;s hanging with the hairdryer wrapped around it. How does, how does hairdryer work? Yep. The rules here are super hazy. Deacon&#39;s up chased by an evil lawnmower that is fucking blood drenched.</p>
<p>Dave! (01:07:35.806)
Or like the lady who&#39;s hanging out the window with her hairdryer? Like, how did the hairdryer kill you?</p>
<p>Exactly.</p>
<p>Dave! (01:07:53.354)
Okay, the lawnmower is the first time I noticed this. I don&#39;t know if you noticed this at all. Every now and then, the car or whatever thing is coming to life will growl. Because I was like, what is that? Is that what that is? And then like, I was like, no, I probably just misheard it. And then it happens like four or five more times with other things.</p>
<p>Bryan! (01:08:06.074)
Yeah!</p>
<p>Bryan! (01:08:10.152)
No. Yeah, it&#39;s like the shark in Jaws 4 where they&#39;re like, we need to give this thing a little menace. So it fucking roars like a lion for some reason. You know, like they do that sound. They definitely do that with sound a little bit because there&#39;s also the ice cream truck scene in this where he tries to evade it by hiding. The ice cream truck is playing a song. Everybody kind of does. Everybody recognizes that those things play songs. So the Mr. Softy theme, the ones that used to drive around our neighborhoods played.</p>
<p>and that ragtime song, the entertainer. And this one is playing King of the Road by Roger Miller, which I think is a really funny detail. It&#39;s a very odd choice, but it&#39;s also kind of ominous because it&#39;s also, it&#39;s the only thing that we see driving around the neighborhood. Like everything else is stopped. So back at the Dixie boy, Duncan decides, Duncan is the guy who got gassed in the face.</p>
<p>Dave! (01:08:47.946)
Yep. It&#39;s an odd choice.</p>
<p>Bryan! (01:09:07.284)
He decides that he&#39;s got to get out there and find his son, Deke. He&#39;s got to find his boy. The only problem is.</p>
<p>Dave! (01:09:12.346)
Uh, hang on just a second. I got to... I&#39;ll be right back.</p>
<p>Bryan! (01:09:15.714)
Okay.</p>
<p>Bryan! (01:10:54.474)
Ahem.</p>
<p>Bryan! (01:10:59.18)
All right. Where the fuck were we? OK, so, yeah, we&#39;re back at the Dixie Boy. Duncan decides that.</p>
<p>Dave! (01:11:05.166)
Wait, did we skip the part where they go into, I think it&#39;s Amelia Westves, goes into Frankie Faison&#39;s truck?</p>
<p>Bryan! (01:11:14.852)
Yes, so that&#39;s, that&#39;s all that. Yep.</p>
<p>Dave! (01:11:15.762)
and he pulls the curtain back and there&#39;s a jack in the box in there. And I was like, why is there a jack in the box strategically set up as though he was waiting for someone to do that?</p>
<p>Bryan! (01:11:24.684)
Yep. Also, but was that was the Jack in the Box also like controlled by the aliens or whatever? It&#39;s the Green Goblin. Yeah. But yeah, that&#39;s like there are so many illusions. Like we&#39;re talking. We&#39;ve mentioned Amityville 2. We&#39;ve mentioned what&#39;s if we just talked about it another episode. Oh fucking maniac. And now we&#39;re talking about another Jack in the Box like in House on Sorority Row. It&#39;s got all the fucking late motifs that have appeared in other episodes of our pod.</p>
<p>Dave! (01:11:33.138)
And it&#39;s also the green, it&#39;s the green goblin. It just came with the truck.</p>
<p>Bryan! (01:11:54.504)
But yeah, great. I did. I did sort of speed past that. Because not much really happens. That&#39;s right before Bill and Brett get together. But yeah, fuck them. But yeah, Duncan is the guy who gets gassed in the face and he&#39;s all blind and shit. So he&#39;s got to get out there and find his boy. As we find out his boy is Deke. There&#39;s a picture very clumsily placed on a desk somewhere of the kid. The only problem is he can&#39;t see shit.</p>
<p>Dave! (01:12:02.519)
BOOOO!</p>
<p>Bryan! (01:12:21.148)
And also, Hendershot threatens him with firing if he goes off in search of his son without clocking out in the middle of this fucking truck apocalypse. But Duncan doesn&#39;t make it very far as a nearby garbage truck fires up and charges at him, taking them under the wheels. Then it&#39;s. Yeah, he drops his keys and he stands there and, you know, screams before he gets hit. Then it smashes into Loman&#39;s truck, dumps garbage all over it.</p>
<p>Dave! (01:12:37.814)
And he just stands there.</p>
<p>Bryan! (01:12:50.052)
Um, so yeah, uh,</p>
<p>But there&#39;s the scene where Loman is enraged by this because he&#39;s a fucking piece of shit and he goes running out and stands there and he&#39;s</p>
<p>Dave! (01:13:03.886)
This is the great part, we&#39;re trying to sell them Bibles, he&#39;s talking about Bibles, and then all of a sudden, he like sees the thing, hit the car, he jumps up and he goes, got in my way, bitch.</p>
<p>Bryan! (01:13:07.63)
Yeah.</p>
<p>Bryan! (01:13:13.861)
Yep. Also, also Hendershot has referred to Brett as a piece of Road Twitch, which is such a fucking gross thing to call a terrible awful thing to call a woman. It&#39;s so fucking gross. Stephen King, man. But yeah, he goes.</p>
<p>Dave! (01:13:22.251)
That&#39;s really gross.</p>
<p>Dave! (01:13:28.158)
Stephen King, ladies and gentlemen. We also skipped the part where Wanda gets attacked by the knife.</p>
<p>Bryan! (01:13:35.056)
Oh shit, that&#39;s right. Yeah. Oh, actually, you know, cause that&#39;s is that happened or is that coming up?</p>
<p>Dave! (01:13:40.342)
That has already happened. It happens pretty early on, I think. Doesn&#39;t it?</p>
<p>Bryan! (01:13:42.44)
Oh.</p>
<p>Hmm</p>
<p>Dave! (01:13:46.698)
It happens like right after we get right when they when they when everyone shows up there and he thing is just fucking sawing into her and she is just losing her goddamn mind.</p>
<p>Bryan! (01:13:57.5)
Right. Because that is actually yeah, that&#39;s very early on. And I had notes about it. But yeah, she it&#39;s just one of the first things that really happens is the there&#39;s one of those like electric carving knives like on the tape on the counter next to where she&#39;s like cooking. And I think kind of like slowly turns on its own and like a fucking asshole. She just like she could have just moved it, but she.</p>
<p>Dave! (01:14:22.614)
Yeah, she just stands there while it saws into her arm.</p>
<p>Bryan! (01:14:25.052)
She reaches underneath the blade and then the thing like comes on cuts her real bad and she kind of wax it and falls onto the floor and then it goes at her like fucking jaws and like saws her foot and she&#39;s</p>
<p>Dave! (01:14:38.402)
This is because this is our first hint that something is wrong</p>
<p>Bryan! (01:14:41.612)
Right. She gets she is like she is like covered in blood by this. And like one of the fucking good old boys in the kitchen is like, hey, one of those things there, bitches, sweetie, like they&#39;re like joking about it. Yeah. And she and then they come to her and it&#39;s like Bill is like helping her. They&#39;ve got like a bloody towel. So there is a lot of blood. This is like this is like elevator in the shining amount of blood.</p>
<p>Dave! (01:14:55.682)
Like you&#39;re gonna die too.</p>
<p>Bryan! (01:15:11.136)
applaud on this girl and they&#39;re there.</p>
<p>Dave! (01:15:13.002)
and she just flies right off the rails and she stays there.</p>
<p>Bryan! (01:15:16.588)
Yeah. Oh, God. Yeah. She is cranked up to the top and they have pulled the knob off for her because she is she is about she&#39;s about to go bucking nuts. But first, Loman charges out into the into the parking lot. And this is when the toy truck suddenly backs into him and he turns. He turns to run from it. And instead of just getting out of the way, he tries to run from it. The thing hits him, sends him flying into a drain. No.</p>
<p>Dave! (01:15:34.902)
Send some flying across the road.</p>
<p>Dave! (01:15:42.39)
Yeah, these are semi trucks. They don&#39;t move that quickly.</p>
<p>Bryan! (01:15:46.524)
takes a while to gain speed. They turn very slowly. You could just leap to the side, but no, he gets he gets hit. He gets launched into a drainage ditch. That&#39;s where he stays. So now all of the trucks in the lot fire up and they begin to drive single file like in a ring around the truck stop.</p>
<p>And this is a thing like I don&#39;t know what it is. I love a good siege movie and this. I have not. It&#39;s been on shutter and I have I have flirted with watching it several times.</p>
<p>Dave! (01:16:18.786)
Hmm. Have you ever seen Siege?</p>
<p>Dave! (01:16:25.438)
It is a very odd movie about a bunch of dudes who escape a gay bar. It&#39;s very strange. I recommend it.</p>
<p>Bryan! (01:16:29.752)
Yeah, yeah. Cool, I&#39;ll have to watch it. It&#39;s been one of those ones.</p>
<p>Dave! (01:16:35.286)
It&#39;s like if assault on precinct 13 took place in a gay bar. It&#39;s basically that. Yup.</p>
<p>Bryan! (01:16:39.384)
I can get it&#39;s Canadian too. So yeah, this, but this, this scene for some reason, like there used to be like we&#39;d see the ads or we&#39;d see the previews or something. I would always use the scene of like the trucks just driving around and around and around and something about that really bothered me when I was like 12. You know, it&#39;s very menacing. But like now as an adult.</p>
<p>Dave! (01:17:00.97)
Because there&#39;s something weirdly ritualistic about it.</p>
<p>Bryan! (01:17:03.812)
Yeah, yeah. Well, I mean, circumambulation is a very big piece of, of like, you know, ordinary sort of like daily ritual practice and a lot of like Western esoteric. So like, so yeah, like I can, I can see that. But like, yeah, something about that really got under my skin as an adult to watch it. And I&#39;m like, ha. So yeah.</p>
<p>Dave! (01:17:21.726)
Yeah. And I and it&#39;s so heavy handed.</p>
<p>Bryan! (01:17:26.18)
Yeah, yeah, because it&#39;s like dust going everywhere right there.</p>
<p>Dave! (01:17:28.924)
Everything in this movie is heavy-handed, but especially this.</p>
<p>Bryan! (01:17:32.248)
Yeah, so we cut to Curt and Connie who are still in the road. They come under attack from another truck. Connie again, screaming and screaming and screaming. Curtis managed to psych out this truck that&#39;s chasing them. This is a kind of a protracted car chasing. That&#39;s pretty cool. And he manages to second off, causes it to run off the road and get a fucking awesome fiery crash. His truck goes out in slow motion.</p>
<p>Dave! (01:17:54.218)
And then as they pull away, he says, I think I just loaded my pants.</p>
<p>Bryan! (01:17:59.064)
Yep, which that might be a good thing for him. Yeah, so when they finally they come up, they come up on the truck stop and thing is that he sees a gap between some of the trucks and he&#39;s like, if I can drive through that gap at just such a speed like we make it through and she&#39;s like, why are we stuck with him, which is a fucking pretty good point is like, but are we going to be safe out here? Maybe I don&#39;t know.</p>
<p>Dave! (01:18:24.882)
Doesn&#39;t seem like it.</p>
<p>Bryan! (01:18:26.008)
No, but so they start the drive and we cut to this like scene from behind the cars. It&#39;s taking a while. It&#39;s taking off in the back of the back of the car says good luck curtain Connie, which I love. I love that little detail, but like yeah, they see them coming from the inside of the truck stop and they&#39;re like what the fuck are these people doing so they go out sort of in the anticipation of helping them and the car almost makes it, but it gets clipped by a truck and they end up going fucking and over and</p>
<p>they&#39;re stuck in and everybody. Yep. Oh my God. Yeah. Because she&#39;s now she&#39;s like legit stuck in the in the thing. She&#39;s like, I told you not to use the safe out for some reason. So I cut her out. And this is when we find out that fucking Hendershot for some fucking reason has a missile launcher and a lot of missiles.</p>
<p>Dave! (01:18:55.946)
And it&#39;s just Connie just shrieking.</p>
<p>Dave! (01:19:14.646)
Yeah, but you know what? There is no, what is more 80s than a goddamn bazooka? If he, the only thing that would have made it even more 80s is if he had an Uzi in one of his other.</p>
<p>Bryan! (01:19:20.212)
Yeah, yeah, like this is</p>
<p>Bryan! (01:19:26.216)
There&#39;s an Uzi in this fucking movie. Yeah, Kurt Curtis ends up with the Uzi at the end. That&#39;s how they yeah. But yeah, like this is this was such a common fucking thing. So we find out his Hendershot&#39;s got like an arsenal in the basement. It&#39;s they never explained why. Like, is he a fucking arms dealer on the side like?</p>
<p>Dave! (01:19:39.99)
But it&#39;s never, they never really explain why.</p>
<p>Dave! (01:19:47.49)
That&#39;s like kind of what&#39;s implied. Like there&#39;s something about that. Cause again, that feels very 80s. This sort of like weapons dealer.</p>
<p>Bryan! (01:19:57.012)
Oh, sure. Like everybody had to have that. Like that was that was straight out of Commando. And then and then Rambo came out and they did the same sort of thing because every movie&#39;s got to have that scene where they&#39;re like loading up and they&#39;re getting ready and there&#39;s always a fucking secret arsenal of weapons.</p>
<p>Dave! (01:20:11.798)
Because these are like, these are not, these are 80s guns. Like these are the biggest fucking weapons you could possibly buy. And he&#39;s got them all in the basement of his truck stop.</p>
<p>Bryan! (01:20:14.713)
Yeah, yeah.</p>
<p>Bryan! (01:20:18.328)
Yeah, and also.</p>
<p>Bryan! (01:20:22.852)
For some reason, it has a testament to how Italian this movie is. Some of those guns, they&#39;re Italian submachine guns. So, yep.</p>
<p>Dave! (01:20:28.79)
Yeah. And it&#39;s the guy, the other, the guy, is it his son? The kind of bumbling idiot?</p>
<p>Bryan! (01:20:35.145)
It&#39;s not really clear what their relationship is, but I sure, why not? But yeah, because there&#39;s a scene where, oh, no, after that, there&#39;s that scene where like fucking Bill goes and sort of intrudes on him as he&#39;s taking a dump and he like looks. So they wreck one truck and then they reload and they hit another one. And because it&#39;s a fucking 80s movie,</p>
<p>Dave! (01:20:49.61)
Yep, that&#39;s where you&#39;re gonna get some fart jokes here.</p>
<p>Bryan! (01:21:02.912)
It&#39;s it that the truck explodes and rolls of burning toilet paper just go in every direction.</p>
<p>Dave! (01:21:09.834)
And you could have had anything. Why toilet paper?</p>
<p>Bryan! (01:21:12.408)
Because it&#39;s toilet paper and it&#39;s funny. Yep, I love that love it.</p>
<p>Dave! (01:21:14.986)
I guess so. Now, they could have probably solved their fucking problem with whatever this arsenal in the basement was, but they just stop. He shoots two of them and he&#39;s like, yeah, okay, I&#39;m done.</p>
<p>Bryan! (01:21:23.728)
They, yeah, there. There is no end to how many fucking missiles he&#39;s got to like. Yeah, they, yeah, they could have could have fucking dealt with it because apparently they&#39;re all really good at shooting these giant trucks with these fucking missiles. So we cut. We find out that Brett is hitchhiking our way to Florida gives her an opportunity to say the name of the movie in time in the dialogue. So my way to Florida.</p>
<p>Dave! (01:21:30.155)
Yeah, he&#39;s got tons of them.</p>
<p>Bryan! (01:21:50.788)
before all of the everything went maximum overdrive or something like that. And I was like, ah, she said it.</p>
<p>Dave! (01:21:55.606)
Ding ding ding! We said the name of the movie, she said.</p>
<p>Bryan! (01:21:59.864)
Yeah, so we cut back to Deke, who is now near the truck stop, and he gets buzzed by Chekhov&#39;s airplane. Nope. And then for some reason, down in the basement, we learn about Bill&#39;s criminal record. This plays no fucking role in the movie whatsoever, but they spend a lot of time like.</p>
<p>Dave! (01:22:06.028)
Yep.</p>
<p>Dave! (01:22:18.866)
It&#39;s only to come back because at the beginning, that&#39;s why he&#39;s like manipulating him and saying, you&#39;re gonna work nine hours, but only clock in for eight. There&#39;s nothing you can do about it because I&#39;ll report you otherwise. It&#39;s only to call back to his criminal past.</p>
<p>Bryan! (01:22:27.15)
Yeah!</p>
<p>Bryan! (01:22:32.952)
guess. I guess because like that initial scene was enough to just go, oh, okay. So he&#39;s just a ex con. Like, okay. But like this is this really drives it home. Apparently like and then after Hendershot goes away, he&#39;s like he talks to Brett and he&#39;s like, well, I guess you found out this my secret. And it&#39;s like, who fucking cares? I don&#39;t care.</p>
<p>Dave! (01:22:53.098)
And then she&#39;s like, I&#39;m so turned on. Your criminal past makes me so horny in this apocalypse we&#39;re in right now. Let&#39;s take an hour out to fuck.</p>
<p>Bryan! (01:23:00.676)
Yep, because I. Yeah, because I believe no, there&#39;s a little bit and then they cut to a scene where they&#39;re just like fucking. Yep, but back outside, Deke finds the drainage ditch and he finds a drainpipe that leads into the truck stop, but there&#39;s a cage on it that he can&#39;t get past. So we set this up. This is Chekhov&#39;s drainpipe. Now night falls on the truck stop. Bill and Brett get down. Like I said.</p>
<p>Dave! (01:23:09.27)
Yeah.</p>
<p>Bryan! (01:23:26.212)
But by night we can see a green glow in the sky and Bill tells Brett about Haven, which is an island off the coast where there&#39;s no cars. And if they can get there.</p>
<p>Dave! (01:23:34.454)
Which is interesting because this movie comes out around the same time, or maybe a little bit before Tommy Knockers comes out. Tommy Knockers takes place on an island in Maine called Haven. And I believe.</p>
<p>Bryan! (01:23:43.932)
Ah, all right, all right. Yeah, so he must have been writing that at the same time. Man, Tommy Knockers is a fucking cocaine book too.</p>
<p>Dave! (01:23:48.3)
I think so.</p>
<p>That&#39;s the one that he wrote after, I think that&#39;s when he got sober.</p>
<p>Bryan! (01:23:55.108)
Yeah.</p>
<p>But yeah, so no cars. If we could just get to a sailboat, he knows how to sail it out there because he used to cruise sailboat conveniently. But what about everything else? No.</p>
<p>Dave! (01:24:07.982)
You don&#39;t even have to explain any of that so much weird crazy shit has happened in this movie And they have not explained any of it now all of the sudden It&#39;s like someone at the studio is like okay hold on everybody you got to at least say X Y &amp; Z it&#39;s like Why now?</p>
<p>Bryan! (01:24:21.824)
Right, yeah, again, studio notes. It&#39;s gotta be producer notes. Like you gotta give us.</p>
<p>Dave! (01:24:27.274)
I wanna know how a Walkman killed someone, not how they&#39;re gonna get to an island.</p>
<p>Bryan! (01:24:31.352)
Yep. But meanwhile, Wanda is cracking up and she run she. This is because she runs outside screaming, we made you, we made you, which is like, you know, it&#39;s the song, it&#39;s called Who Made Who, right? Yeah, we made you. But she&#39;s not just screaming, she is just wildly her.</p>
<p>Dave! (01:24:34.558)
Oh man, this is no wire hangers ever. That is what this performance is.</p>
<p>Dave! (01:24:50.443)
Yeah.</p>
<p>Dave! (01:24:56.294)
It is wild, she is just-</p>
<p>Bryan! (01:24:58.788)
Her body is jerking in every direction. She&#39;s just...</p>
<p>Dave! (01:25:01.15)
It&#39;s like a weird kabuki dance. Like she is just flailing wildly, but in this like herky jerky movement that looks like she&#39;s kind of like in a cartoon, she drank some poison and now her body is like locking up before she goes rigid and dies. It&#39;s like that.</p>
<p>Bryan! (01:25:16.572)
Now that or she&#39;s having a seizure. Like it&#39;s crazy what she&#39;s doing. The way that she, the way that she writhes and undulates. Yeah, it&#39;s, yeah, it&#39;s nothing. So the trucks, so they go out, they pull her back in. But the trucks cut the power of the truck stop. And in the darkness, everybody can hear the Bible salesman out in the ditch screaming. So he&#39;s still alive, but he&#39;s badly hurt. And so...</p>
<p>Dave! (01:25:21.066)
And she is just top volume.</p>
<p>Yes, exactly, it&#39;s that.</p>
<p>Bryan! (01:25:42.436)
We cut out, Deke finds a way into a drainage pipe as a huge convoy of trucks comes driving in. So now there&#39;s more fucking trucks.</p>
<p>Dave! (01:25:50.722)
This movie is so unevenly paced, it is like wildly uneven in its pacing because there are huge stretches where you&#39;re just like, God fucking step on it already.</p>
<p>Bryan! (01:25:55.355)
Yeah, it goes hard and then it...</p>
<p>Bryan! (01:26:01.288)
Yeah, yeah, and it&#39;s not a very long movie. It&#39;s about 98 minutes long and</p>
<p>Dave! (01:26:05.585)
It&#39;s 88 minutes or something. It&#39;s like just at the threshold.</p>
<p>Bryan! (01:26:08.492)
Yeah, so yeah, Bill and Curtis have a plan to go out and help Loman by sneaking out of the showers and down through a drain. So down into the shit closet, they go.</p>
<p>Dave! (01:26:18.61)
And so, and this is another part where it&#39;s like the tone of this movie is also really weird and all over the place, because all of a sudden it&#39;s like this weird buddy comedy in the sewer.</p>
<p>Bryan! (01:26:24.313)
Yeah!</p>
<p>Bryan! (01:26:28.14)
Yeah, because again, and this is it. This is where Curtis again invites us into his world of piss play, asking how many people you think of Pied in this? And this being knee deep water that they&#39;re wading through. So it&#39;s super gross. It&#39;s just the implication of what they&#39;re doing, because they&#39;re like, they&#39;re not just like wading through it. They&#39;re like hands and knees through this. I&#39;m just like, oh, yeah. Oh, God, he&#39;s.</p>
<p>Dave! (01:26:48.502)
Yeah, and then Emilio Estevez like slips or something and it splashes into his face and it&#39;s like this real kooky moment.</p>
<p>Bryan! (01:26:55.undefined)
Yeah, he has definitely got like hepatitis and like, hey, this is so nasty.</p>
<p>Dave! (01:27:01.29)
It&#39;s just so, it&#39;s such a weird, like hard turn from like horror action movie to weird comedy.</p>
<p>Bryan! (01:27:09.156)
Yeah, yeah. So they come out the other side of the drainpipe. It&#39;s it&#39;s Deke and he finds Loman just laying there in the ditch and he thinks he&#39;s dead. But he&#39;s wrong because as he&#39;s getting away Loman suddenly lunges and grabs him demanding that Deke pull him to safety is like I&#39;ll kill you and like all this stuff. The kids like I can&#39;t you&#39;re too heavy and he&#39;s this but this is when Bill and Curtis come along and they find him and they grab him and I duck back into the drain just as a dump truck charges down on them.</p>
<p>and into the ditch tries to take him out. But they make it back into the diner, taking out another truck with a missile on the way in. This is when Deke finds out that his dad&#39;s dead. So in the morning, we find a bulldozer in this little military truck with a machine gun mounted on it, come driving up to the truck stop. Bulldozer trashes Hendershot&#39;s car, pushes it into the diner. We can&#39;t help but feel like it did the right thing like that. That did nothing wrong.</p>
<p>Dave! (01:28:05.67)
Mm-hmm.</p>
<p>Bryan! (01:28:07.088)
a hander shot fires a missile at the bulldozer, which doesn&#39;t do a whole lot because it&#39;s a piece of heavy.</p>
<p>and the dish.</p>
<p>Dave! (01:28:13.322)
And meanwhile he&#39;s stuffing missiles into his pocket. Yeah, that doesn&#39;t seem like a good idea.</p>
<p>Bryan! (01:28:16.332)
Yeah, yeah. And he&#39;s, he&#39;s like, you want, he&#39;s like, you want a war? I&#39;ll give you a war. And then just as soon as he says that the machine gun truck just strafes the truck stop.</p>
<p>Dave! (01:28:28.831)
Art Händel is a great actor. I love that guy.</p>
<p>Bryan! (01:28:31.76)
And he gets everybody gets everybody standing up basically gets hosed down with machine gun bullets and we get like a basically a robo cop level of blood. As this happens like it&#39;s squib city I</p>
<p>Dave! (01:28:43.326)
It is such a great moment though, like, cause you can just see it, they&#39;re all just exploding all over people. And you can tell that it&#39;s not, cause it&#39;s external, it&#39;s blowing out not.</p>
<p>Bryan! (01:28:53.176)
Yeah, yeah, that&#39;s also that&#39;s probably they don&#39;t they don&#39;t do it in this movie, but they do it in a lot of movies. I think the fucking bothers me is when they do the thing where they shoot him in the head and the shit blasts against the window, but the fucking window doesn&#39;t break. God, it&#39;s a yeah, I&#39;ve recently I can&#39;t remember what it was. I saw something might have been on TV, where they actually did break the window. And I was like, Thank you. So yeah, but yeah, the</p>
<p>Dave! (01:29:05.551)
Mm-hmm.</p>
<p>Dave! (01:29:16.168)
This moment of chaos is really fantastic.</p>
<p>Bryan! (01:29:18.86)
Squibs are the best. I fucking love them. I wish they did that bit. Like every, they&#39;re messy and you know, it&#39;s hard to clean up and do, you know, if you need to do multiple shots. So now that&#39;s all. Yep, yep. So she gets, she does, she, again, she freaks out and she grabs the missile launcher off of hander shot. And she goes out again, screaming, we made you! But this time for her troubles, she gets just.</p>
<p>Dave! (01:29:28.234)
And unfortunately, this is also where Wanda buys it.</p>
<p>Dave! (01:29:42.484)
Mm-hmm.</p>
<p>Bryan! (01:29:46.94)
Hosed down with bullets. Accidentally fires a missile at a truck full of Miller Highlife. Which awesome explosion. The thing fucking goes everywhere. There&#39;s beer cans flying. And this must have been a subconscious middle finger to his alcoholism. Just you know, because I believe he was a Miller Highlife guy. Yep. The champagne of beers. I don&#39;t miss it.</p>
<p>Dave! (01:29:49.748)
R.I.P.</p>
<p>Dave! (01:29:54.144)
Yep.</p>
<p>Dave! (01:30:09.994)
Pain my ass.</p>
<p>Bryan! (01:30:17.064)
But yeah, so what we find out now, because they start to hear like a beeping, I think it&#39;s the one of them is beeping their horn or something like that. And luckily, yeah, luckily, Deke knows more Morse code.</p>
<p>Dave! (01:30:26.012)
Oh yeah, Morse code.</p>
<p>And why does he know he just got a merit badge in it? And I&#39;m like, did you? You&#39;re like, tell the Swinton, I don&#39;t know who you are, what you are, how old you are. He could be 12 or 38 for all I know.</p>
<p>Bryan! (01:30:32.268)
merit badge. Yeah.</p>
<p>Bryan! (01:30:39.685)
How old are you, Deke?</p>
<p>Bryan! (01:30:44.605)
Yeah, but what we find out is the trucks are running out of gas and they&#39;re like, you&#39;re going to come out here and pump gas for us, fill us back up. If you do this like no one.</p>
<p>Dave! (01:30:54.87)
And this is where, this you&#39;re at the point where it&#39;s just like, you just need to accept that this is what&#39;s happening in the movie.</p>
<p>Bryan! (01:30:59.888)
This is such a fucking long part. So this is the thing is in the short story trucks for a short that&#39;s like 20 pages long, the bulk of the story is about people pumping gas just endlessly and this scene goes on forever. But at least it&#39;s set to hell&#39;s bells because that song fucking rules. Yeah, but everybody takes turns at the pumps pumping up a huge line of truck. They do a lot of like crane shots in this to sort of like be like.</p>
<p>Dave! (01:31:18.007)
Yeah.</p>
<p>Bryan! (01:31:28.736)
Ooh, it&#39;s not just these guys. Look here over the horizon. There&#39;s trucks just down the street for the horizon.</p>
<p>Dave! (01:31:35.114)
And they make it seem like whatever they&#39;re doing is like the most arduous manual labor. Like this is a thing people do for a living every day. They pump gas all day long. They&#39;re acting like he&#39;s pumping gas, like the thing is on fire when he&#39;s doing it.</p>
<p>Bryan! (01:31:46.014)
Oh, yeah</p>
<p>Bryan! (01:31:50.64)
Oh yeah, but it&#39;s hot and it&#39;s dusty. But oh yeah, also there&#39;s a shot where it&#39;s like, they show his hands, his hands are all fucking callous and blister from fucking pumping gas. But like everybody, like everybody&#39;s taking turns and the songs play in and they&#39;re all.</p>
<p>Dave! (01:32:03.33)
He&#39;s not like an 18th century ditch digger. Like you&#39;re pumping gas.</p>
<p>Bryan! (01:32:06.072)
No. Yeah, they&#39;re all like, you know, wiping their fucking brow and like being exhausted and shit. But the thing that they really want you to know is it&#39;s hot and it&#39;s dusty, which this is a thing that goes a long way in these movies.</p>
<p>Dave! (01:32:26.61)
Oh yeah, this is when he starts like giving that, he has this weird monologue about brooms and aliens. And I&#39;m watching, like what the fuck is he talking about? It&#39;s more exposition, I think, but.</p>
<p>Bryan! (01:32:32.016)
Yeah!</p>
<p>Bryan! (01:32:36.092)
Yeah, so like the really the big. Yeah, because so there&#39;s the big threat in this scene is heat. They&#39;re all they&#39;re all. It&#39;s it&#39;s a really solid way to sort of sell the peril of a siege movie. He did this in Cujo and the adaptation really fucking leans into that because most of that movie is D Wallace and Danny Pintaro in a car screaming car that is covered in dog drool and it&#39;s hot and it&#39;s dusty. It&#39;s so.</p>
<p>Dave! (01:32:57.518)
Screaming. Screaming in a car.</p>
<p>Bryan! (01:33:05.552)
Fucking nasty. It&#39;s just one of the most</p>
<p>Dave! (01:33:07.33)
I remember that movie being really good. I won&#39;t watch it again, because it&#39;s got animal stuff that I don&#39;t want to see, but I remember it being a pretty good movie.</p>
<p>Bryan! (01:33:15.564)
Yeah. And also like this, that&#39;s, that&#39;s something that really works to the advantage of Texas chainsaw massacre as well. Like, you know, like the hot and dusty just. It was really, really hot and really dusty. Yeah. It&#39;s. Yeah. It&#39;s something that just looks nasty. You don&#39;t even need really to have direct experience with hot and dusty. Like you just, the visual representation of it is very impressive. It sucks.</p>
<p>Dave! (01:33:22.434)
except because that&#39;s what was really happening there. They were actually dying in that heat and Tobe Hooper pressed on.</p>
<p>Dave! (01:33:39.19)
It&#39;s a thing that everybody can recognize and understand.</p>
<p>Bryan! (01:33:42.712)
Yep. So this is the part that you were just talking about where on the edge of exhaustion, Bill theorizes that it&#39;s not the comet that&#39;s controlling the trucks. It&#39;s these aliens and they&#39;re here to clear out the people who live here so they can move in. So keep this in mind for when the fucking movie ends.</p>
<p>Dave! (01:33:58.334)
And you watch you watch the part you think, sure, why not?</p>
<p>Bryan! (01:34:01.292)
Yeah, I guess this makes perfect sense at this point. Handy would not have mattered because we&#39;re rounding the corner. We&#39;re coming towards the end. Handy reveals that they found a pipe in the basement that they can all escape through that leads to the same blocked pipe that Deke found originally. You know, I&#39;ll say this for as insane as this fucking movie is, it&#39;s at least narratively consistent in some of the shit that it sets up. Like this is kind of like a meaningless callback, but like</p>
<p>Dave! (01:34:05.686)
Could&#39;ve said anything, it wouldn&#39;t have mattered.</p>
<p>Bryan! (01:34:32.113)
It connects the dots are connected in a way that makes sense, even while nothing else is really making sense. I&#39;m going to give it that</p>
<p>Dave! (01:34:36.494)
Well, that&#39;s the thing. It&#39;s so weird. It&#39;s like, it explains some weird shit like the grate on the pipe. It goes to great lengths to explain that or to explain why he is working nine hours but only getting paid for eight. It goes out of its way and it doesn&#39;t explain anything else. It&#39;s just like, why are these watermelons falling so goddamn fast and hard? Who cares?</p>
<p>Bryan! (01:34:59.88)
Yeah. So yeah, yeah. So so they can escape through this great if they can if they can move it. So Bill, Bill has this plan and he goes out. And you&#39;re like that machine gun truck is there. It&#39;s just tracking everybody with a gun. And he he&#39;s he grabs a guy who&#39;s like sitting on the bulldozer like I am presumably gassing it up. And he&#39;s like, Hey, I&#39;ve got a plan. When I say run, run.</p>
<p>hell of a plan, Bill. But when he does this, he&#39;s like, run, and he produces a fucking hand grenade. He throws it at the machine gun truck, which blows it up into a million pieces. And so everybody then like piles down into the into the basement, they go out, you know, they make their escape. But now the trucks are like, we must have revenge. And so they go full convoy on the</p>
<p>Dave! (01:35:31.52)
Mm-hmm.</p>
<p>Dave! (01:35:51.542)
And this is the rest of their explosions budget. This is all of it, they&#39;re gonna use it all. It&#39;s like the end of a fucking fireworks display.</p>
<p>Bryan! (01:35:54.84)
You know, it&#39;s, it&#39;s they. Yeah, and so they go, they the trucks go full convoy, they crash through the place, their mother knocking over everything. And while this is happening, like a truck full of like liquid oxygen, like crashes into the Dixie boy and then ignites. And the whole fucking building just goes up in a humongous fireball piece.</p>
<p>Dave! (01:36:20.222)
Is that a miniature or did they really blow that up? Because at a certain point, it looks like it&#39;s really like it&#39;s a landscape.</p>
<p>Bryan! (01:36:22.818)
So they really...</p>
<p>Bryan! (01:36:27.288)
It is they built they built that truck stop just for the movie so they could. I look you know I&#39;m gonna look this up because that&#39;s a really good question, but. It oh yeah, this was this was a hit. This was not. You know it&#39;s a crappy movie, but OK, so the budget was oh OK, so the budget was $9 million box office 7.4. But it is it is definitely so.</p>
<p>Dave! (01:36:32.399)
What is the budget on this movie? It has to be insane.</p>
<p>Because I believe this actually did pretty well, didn&#39;t it? Financially.</p>
<p>Dave! (01:36:54.244)
Ow.</p>
<p>Bryan! (01:36:57.176)
Box office, when it was De Laurentiis movies, it didn&#39;t really fucking matter if a movie did well or not in terms of box office. Really, his strategy, his strategy was selling rights to overseas. So like, he would make, like, it could be a flop here, but it would make up the, you know, more than make up for it elsewhere. So that&#39;s what he was doing. So that&#39;s just domestic box.</p>
<p>Dave! (01:37:05.779)
This is quantity, not quality.</p>
<p>Bryan! (01:37:24.944)
That movie, this movie definitely made its fucking budget back and definitely turned a profit. And it certainly like in the age of like video rental and since, you know, fucking Blu-ray, DVD and all that stuff.</p>
<p>Dave! (01:37:35.662)
Because they don&#39;t include that, right, when they do revenue.</p>
<p>Bryan! (01:37:38.444)
No, no, no box office is just box office. And so like we and that&#39;s the thing we never really find out. I don&#39;t know if there&#39;s a way. I don&#39;t think it&#39;s reported on like they just these movies, they.</p>
<p>Dave! (01:37:49.43)
What you would do, you could do is you&#39;d have to look at sound scan.</p>
<p>Bryan! (01:37:52.556)
Yeah, yeah, but like these movies definitely like. They might be like a box office flop, but like they definitely video rental was a fucking humongous mover. So like I&#39;m sure that like the fucking take for this movie came in and they were like, oh yeah, well, you know we&#39;re a million shy of our budget. Whatever will make it up, you know the next year and just video rental. You know it&#39;s like it&#39;s one of those things. You know this movie, you know it definitely did OK.</p>
<p>So everybody manages to escape huge AD style explosion. Must&#39;ve been fucking awesome to shoot that. Like just to watch that from a distance as that thing goes up because it is.</p>
<p>Dave! (01:38:31.534)
I mean, so that $9 million budget today would be $25 million.</p>
<p>Bryan! (01:38:35.312)
Jesus Christ, I mean, that&#39;s not a small budget. Well, I mean, yeah. But yes, they built that truck stop as a set. I believe it was on De La Renta&#39;s property.</p>
<p>Dave! (01:38:37.462)
Yeah. Well, I mean, because they just blew up half of North Carolina, so.</p>
<p>Dave! (01:38:52.586)
Yeah, they own they own like a big like a not a resort, but like a huge piece of land.</p>
<p>Bryan! (01:38:59.032)
Yeah. So as everybody escapes, the Green Goblin takes to the highway and we got our payoff to Chekhov&#39;s airplane as we see it fully vertical nose down in the middle of a school bus crashed. So yeah, we had some we had a little bit more action in the burbs as they&#39;re nearly found out by a speaker outside of a fast food joint.</p>
<p>Dave! (01:39:08.759)
Yeah, school bus, yeah.</p>
<p>Dave! (01:39:17.982)
Now I think I&#39;ve got some questions about this. It is a fast food, they&#39;re outside the fast food place and the speaker, it&#39;s like humans near or whatever, keep saying, here&#39;s how speakers work. Someone has to say something in order for it to be transmitted. Who is saying something?</p>
<p>Bryan! (01:39:20.623)
Yeah.</p>
<p>Bryan! (01:39:28.056)
Yeah, something like that.</p>
<p>Bryan! (01:39:35.948)
Yep. Hey, let me let me let me let me answer that with a question. Why are you asking questions right now?</p>
<p>Dave! (01:39:41.566)
Like, you know who&#39;s saying that? Cocaine. That&#39;s just cocaine talking at you.</p>
<p>Bryan! (01:39:46.716)
So yeah, this is when Deke steps up with his fucking gun and just like hoses it down with bullets and then he and then he relinquishes it to Brett and he&#39;s like, I won&#39;t need this anymore because he&#39;s like, that&#39;s for my dad, he says. And then that was his revenge for his dead father. She and then the ice cream truck reappears and is promptly shot up by Curtis with his with his Uzi and like.</p>
<p>Dave! (01:39:51.935)
Yep.</p>
<p>Dave! (01:40:00.194)
What?</p>
<p>Dave! (01:40:04.226)
The fuck is happening at this point?</p>
<p>Dave! (01:40:12.458)
With glee, no less.</p>
<p>Bryan! (01:40:14.592)
With a huge fireball, it comes flying out of the fireball as well. Like, they&#39;re really pulling out all the stops now. Like, we gotta have it. And the gang finally gets to the marina where they find a boat. But one of the diner extras, who I don&#39;t believe has a name, like even if he did, who gives a shit? But he spots a lady hanging out of her car wearing a huge gaudy diamond ring. He stops to strip it off of her corpse. Yep.</p>
<p>Dave! (01:40:40.046)
It&#39;s gonna get us comeuppance.</p>
<p>Bryan! (01:40:42.048)
And just in a perfectly Stephen King moment, he ends up run down for his avarice by the green goblin. And then just as everybody gets on board the boat, the green goblin comes charging in, but Bill hits it with another missile, like right in the fucking. Yeah, right. Like it is a one liner. I can&#39;t remember. It&#39;s like, you know, eat this or something like that, because it goes right in his mouth, causing it to explode go all over the goddamn place. The thing is, is that.</p>
<p>Dave! (01:40:54.762)
Yeah, this shit ain&#39;t over yet.</p>
<p>Bryan! (01:41:11.068)
Green Goblin like face piece. There were like several of them, obviously, and I believe like one of them survived, but like it ended up in like some Boneyard for a while just kind of rotting and some dude found it and was like, I asked that movie. I love that. And so he kept it and then sort of exchanged hands with a couple of collectors before it ended up in the hands of a guy who like fully restored it. Now he takes it around to cons and stuff like that. So you can like it is cool. Like I it&#39;s.</p>
<p>Dave! (01:41:35.274)
It&#39;s pretty cool looking. I mean, as far as like, like this movie is dumb as shit, but it, you know, the eyes light up, it&#39;s pretty cool. Yeah.</p>
<p>Bryan! (01:41:41.732)
Yeah, they turn red. It&#39;s a very, I love it. It&#39;s a really, it doesn&#39;t make any fucking sense, but neither does anything else in this movie, but it&#39;s a really awesome little detail that I...</p>
<p>And so they sail to safety and we get the last title card which explains that a UFO in orbit was found and destroyed by a Russian weather satellite in quotes that had nuclear missiles and a laser cannon on it. Yep, class four. Hey you want to?</p>
<p>Dave! (01:42:07.238)
a laser cannon and class 4 nuclear missiles. This is the most 1980s shit I have ever read.</p>
<p>Bryan! (01:42:15.832)
Yeah, you want to know something? Totally fucking meaningless. There&#39;s nothing. What the fuck is that? It sounds very fancy and very sci-fi.</p>
<p>Dave! (01:42:22.466)
Well, how did it end? Science and lasers.</p>
<p>Bryan! (01:42:25.664)
Yep. And so just before the credits roll, we get one last Connie scream as she announced it because she&#39;s seasick seasick. She&#39;s going to and I quote whoops her cookies. Roll credits. Yep. Oh, but we do we do get a one of the one of the titles at the end, like after the nuclear missiles thing is the Dixie Boy survivors are still survivors.</p>
<p>Dave! (01:42:36.83)
Yep, ba-dum-bum.</p>
<p>And then it just fucking ends. This wild, wild ride.</p>
<p>Dave! (01:42:52.298)
That&#39;s right. And then I don&#39;t know if you noticed, for some reason as the credits start to roll, the casting director is the top of the credits. Cause I mean, I think they were like, look who pulled this shit together.</p>
<p>Bryan! (01:43:00.664)
is the first credit. Yeah.</p>
<p>Bryan! (01:43:08.531)
Yeah. Yup. They&#39;re fucking-</p>
<p>Dave! (01:43:09.578)
Yeah. Fucking Nancy from the casting company, she&#39;s the one to talk to if you&#39;ve got questions.</p>
<p>Bryan! (01:43:14.9)
Yeah, maximum overdrive you guys. Fuck yes. It&#39;s it&#39;s this is. Like I said, it&#39;s 100% cocaine. It&#39;s a crazy fucking 80s action horror movie. It&#39;s.</p>
<p>Bryan! (01:43:33.304)
About as dumb as dumb gets, but it is so fucking fun. Everything.</p>
<p>Dave! (01:43:38.21)
There I cannot think of a single movie that is like this. Because they don&#39;t give inexperienced people this much money to make movies.</p>
<p>Bryan! (01:43:43.461)
Well...</p>
<p>Bryan! (01:43:47.336)
No, oh, in that regard, absolutely not. I cannot think of anything like that.</p>
<p>Dave! (01:43:51.478)
Like this movie is so badly directed, it is a wonder that it actually completed. But the fact that they were just like, oh sure, Stephen King wants to make a movie, here is $9 million.</p>
<p>Bryan! (01:44:01.356)
Nine million dollars. Yeah, like I don&#39;t even like I mean when like Rob Zombie wanted to make his Yo, yeah. Yeah. Yeah, like when Rob Zombie made his</p>
<p>Dave! (01:44:05.694)
and a legit cast. When Rob Zombie, they were like, fine, but you get Bill Mosley. Like, we&#39;re not gonna give you, like, you&#39;re not getting Tom Cruise.</p>
<p>Bryan! (01:44:12.676)
Yeah, but he wanted Bill Mosley.</p>
<p>Bryan! (01:44:16.876)
Oh yeah, you&#39;re not getting like, you&#39;re not getting like, you get Rainn Wilson and Sid Haig, but even he wanted Sid Haig. But also he didn&#39;t get like fucking $9 million. I don&#39;t know what House of 1000 Corpses cost, but.</p>
<p>Dave! (01:44:22.678)
But it is so-</p>
<p>Dave! (01:44:26.974)
And even Rob Zombie kind of knows how to, I think Rob Zombie is actually a competent director. I think his movies are terrible, but I think he&#39;s a good director. But this is the embodiment of the 80s. This is a person who has so much wealth and privilege and power. And he says, I wanna direct a movie. And everybody goes, okay. There are people who fight their entire lives just to make a short film. People with talent and drive and determination.</p>
<p>Bryan! (01:44:31.662)
Oh sure!</p>
<p>Dave! (01:44:56.186)
and Stephen King walks into a fucking studio and is like, I wanna make a movie. And they&#39;re like, all right, cool.</p>
<p>Bryan! (01:45:01.304)
Yeah, Stephen King drunk with power and beer.</p>
<p>Dave! (01:45:03.574)
You can just buy your way into being a director and then talk about it as though you&#39;re gonna do it again.</p>
<p>Bryan! (01:45:07.308)
Yeah, I mean, but also.</p>
<p>Bryan! (01:45:11.916)
Yeah. People have since asked Stephen King, like, why haven&#39;t you directed again? And his reply is, haven&#39;t you seen Maximum Overdrive? It&#39;s. Yeah. Oh, yeah. It&#39;s extraordinarily difficult to make a fucking movie, particularly when there&#39;s like a major studio behind it, and you&#39;ve got a real cast of people and a shitload of money. It&#39;s it&#39;s, you know, I mean, I suppose to the average.</p>
<p>Dave! (01:45:22.588)
Yeah, cuz it turns out it&#39;s really fucking hard.</p>
<p>Bryan! (01:45:40.432)
person in the 1980s like Stephen King was huge. He still is, but then it&#39;s</p>
<p>Dave! (01:45:45.91)
Now this was this is Michael Jackson level. Well, not quite, but it&#39;s up for a writer.</p>
<p>Bryan! (01:45:52.012)
Yeah, yeah. And it just I guess it just makes sense that he would want to make a movie. And like if somebody was like, Stephen King wants to direct a movie. Yeah. Oh, yeah. Yeah.</p>
<p>Dave! (01:45:59.058)
and just think he could. Because he&#39;s hung around enough movie sets. Dude, those are not the same thing.</p>
<p>Bryan! (01:46:06.904)
Yeah, but, uh, yep, Maximum Overdrive. I love it to death. It is a tremendously bad movie, but good God, is it great. So...</p>
<p>Dave! (01:46:15.978)
It just has, it has these weird pacing moments where you&#39;re just like, alright, I&#39;m kinda over this, and then something else happens, and you&#39;re right back in. Oh!</p>
<p>Bryan! (01:46:23.439)
Yep. Just when I thought I was just when I thought I was out, they pulled me back in. So what are we what are we doing? How does our second week of Stephen King rules go?</p>
<p>Dave! (01:46:32.99)
Uh, well, it is a, we&#39;re going to be watching the criminally underseen and poorly remembered werewolf film, Silver Bullet.</p>
<p>Bryan! (01:46:41.936)
Silver Bullet. And I again another one that I just watched recently. I went on a Stephen King binge a little while ago, much better than I remember it being. Yep. So be here in two weeks when we when we do Silver Bullet.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/silver-bullet">Next Episode</Link></li>
                        <li><Link to="/episodes/sleepaway-camp">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)